import React, { useEffect, useState } from 'react';
// third-party
import ReactApexChart from 'react-apexcharts';

// // chart options
const barChartOptions = {
    chart: {
        type: 'line',
        height: 350,
        stacked: true,
        toolbar: {
            show: false
        }
    },
    plotOptions: {
        line: {
            borderRadius: 4,
            horizontal: true
        }
    },
    dataLabels: {
        enabled: false
    },
    colors: ['#60D0F7', '#00BFB3', '#00BFB3', '#B9F6CA'],
    legend: {
        fontFamily: `'Roboto', sans-serif`,
        position: 'bottom',
        offsetX: 10,
        offsetY: 10,
        markers: {
            width: 16,
            height: 16,
            radius: 15
        },
        itemMargin: {
            horizontal: 15,
            vertical: 8
        }
    },
    responsive: [
        {
            breakpoint: 600,
            options: {
                yaxis: {
                    show: false
                }
            }
        }
    ]
};

interface BarChartProps {
    series: any[];
    text?: string;
    categories?: any[];
    colors?: any[];
    fromRevenue?: boolean;
}

// ==============================|| BAR CHART ||============================== //

const LineChart: React.FC<BarChartProps> = ({ series = [{ data: [], name: '' }], text, categories = [], colors, fromRevenue }) => {
    const [options, setOptions] = useState<any>(barChartOptions);

    useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            xaxis: {
                labels: {
                    formatter: (val) => {
                        return val;
                    }
                }
            },
            yaxis: {
                labels: {
                    rotate: 0
                },
                title: {
                    text
                }
            },
            // tooltip: {
            //     x: {
            //         formatter: function (val) {
            //             return fromRevenue ? `$ ${val}` : val;
            //         }
            //     }
            // },

            // tooltip: {
            //     theme: 'light'
            // },
            legend: {
                show: false
            }
        }));
    }, [text]);

    useEffect(() => {
        if (colors && colors.length > 0)
            setOptions((prevValue) => ({
                ...prevValue,
                colors
            }));
    }, [colors]);

    useEffect(() => {
        if (categories && categories.length > 0)
            setOptions((prevValue) => ({
                ...prevValue,
                xaxis: {
                    categories
                }
            }));
    }, [categories]);

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="line" height={350} />
        </div>
    );
};

export default LineChart;
