/* eslint no-underscore-dangle: 0 */

import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useSnackbar from 'hooks/common/useSnackbar';
import useGQL from 'hooks/useGQL';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ERoutes from 'routes/ERoutes';
import { openSnackbar } from 'store/slices/snackbar';
import Lang from '../../constants/language';

interface ActionMenuProps {
    promoCode: any;
    handleRefetch: any;
}

export const ActionMenu = (props: ActionMenuProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { promoCode, handleRefetch } = props;
    const { getConfirmation } = useAlertDialog();
    const { handleOpenSnackbar } = useSnackbar();

    const { CHANGE_STATUS_PROMO_CODE, DELETE_PROMO_CODE } = useGQL();

    const [handleDeletePromoCode] = DELETE_PROMO_CODE();
    const [handleChangeStatusPromoCode] = CHANGE_STATUS_PROMO_CODE();

    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState('');

    const handleMenuActionClick = (event: any, promoCodeId: string) => {
        setAnchorElAction(event?.currentTarget);
        setSelected(promoCodeId);
    };

    const handleActionClose = () => {
        setAnchorElAction(null);
        setSelected('');
    };

    const handleChangeStatus = async (event: any, promoCodeId: string, status: string) => {
        const isConfirm = await getConfirmation({
            title: Lang.MODAL_CHANGE_STATUS_TITLE('promo code'),
            message: Lang.MODAL_CHANGE_STATUS_MESSAGE('promo code')
        });

        handleActionClose();
        if (isConfirm) {
            try {
                const promoCodeChangedStatus = await handleChangeStatusPromoCode({
                    variables: {
                        input: {
                            isActive: status === 'active' ? false : true,
                            promoCodeId
                        }
                    }
                });
                handleRefetch();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: promoCodeChangedStatus?.data?.changeStatusOfPromoCode?.message,
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
            } catch (err: any) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: err?.message,
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };

    const handleDelete = async (promoCodeId: string) => {
        const isConfirm = await getConfirmation({
            title: Lang.MODAL_DELETE_TITLE('promo code'),
            message: Lang.MODAL_DELETE_MESSAGE('promo code')
        });

        handleActionClose();

        if (isConfirm) {
            try {
                const promoCodeDeletedStatus = await handleDeletePromoCode({
                    variables: {
                        deletePromoCode: {
                            promoCodeId
                        }
                    }
                });
                handleRefetch();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: promoCodeDeletedStatus?.data?.deletePromoCode?.message,
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
            } catch (err: any) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: err?.message,
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };

    return (
        <>
            <IconButton onClick={(e) => handleMenuActionClick(e, promoCode._id)} size="large">
                <MoreVertIcon fontSize="medium" aria-controls="menu-popular-card-1" aria-haspopup="true" />
            </IconButton>
            <Menu
                id="menu-popular-card-1"
                anchorEl={anchorElAction}
                keepMounted
                open={Boolean(anchorElAction)}
                onClose={handleActionClose}
                variant="selectedMenu"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem>
                    <ListItemText
                        primary="Edit"
                        onClick={() => {
                            navigate(ERoutes.promoCodeEdit.replace(':id', promoCode._id));
                        }}
                    />
                </MenuItem>
                <MenuItem>
                    <ListItemText primary="Delete" onClick={(event) => handleDelete(promoCode._id)} />
                </MenuItem>
                <MenuItem>
                    <ListItemText
                        primary="Active/Inactive"
                        onClick={(event) => handleChangeStatus(event, promoCode._id, promoCode.status)}
                    />
                </MenuItem>
            </Menu>
        </>
    );
};
