import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, TextField, Typography, useTheme } from '@mui/material';

interface StaticSelectProps {
    placeholder: string;
    onChange?: (e: any, v: any) => void;
    options: any[];
    value?: string;
    name: string | undefined;
    setFieldValue?: any;
    onBlur: any;
    isObject?: boolean;
    unit?: string;
    selectStyle?: any;
    disabled?: boolean;
}

export const StaticSelect = ({
    options,
    onChange,
    onBlur,
    placeholder,
    value,
    name,
    unit,
    setFieldValue,
    isObject,
    disabled = false
}: StaticSelectProps) => {
    const theme = useTheme();
    return (
        <TextField
            sx={{
                '.MuiSelect-select': {
                    py: '10px'
                }
            }}
            fullWidth
            name={name}
            onBlur={onBlur}
            select
            value={value}
            disabled={disabled}
            SelectProps={{
                IconComponent: ExpandMoreIcon,
                onChange: onChange,
                displayEmpty: true,
                renderValue:
                    value !== ''
                        ? undefined
                        : () => (
                              <Typography color={theme.palette.grey[500]} fontWeight={400} fontFamily={'Nunito, sans-serif'}>
                                  {placeholder}
                              </Typography>
                          )
            }}
            InputProps={{
                endAdornment: isObject ? (
                    <Typography
                        color="primary.main"
                        fontWeight={'normal'}
                        sx={{ position: 'absolute', right: 30, textTransform: 'capitalize' }}
                    >
                        {`${unit}`}
                    </Typography>
                ) : null
            }}
        >
            {options.map((item) => (
                <MenuItem key={item.value} value={isObject ? item : item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </TextField>
    );
};
