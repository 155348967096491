import { useState } from 'react';
import PhoneInputCom from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

interface IphoneInput {
    value: any;
    onChange: (phone: string) => void;
    error?: boolean;
    placeholder: string;
    inputStyle?: {
        [key: string]: any;
    };
}

const PhoneInput = (props: IphoneInput) => {
    const [isFocused, setisFocused] = useState(false);
    const [isHover, setisHover] = useState(false);
    return (
        <div onMouseOver={() => setisHover(true)} onMouseOut={() => setisHover(false)}>
            <PhoneInputCom
                country={'au'}
                enableSearch={true}
                value={props.value}
                onChange={props.onChange}
                onFocus={() => setisFocused(true)}
                onBlur={() => setisFocused(false)}
                placeholder={props.placeholder}
                inputStyle={{
                    ...props.inputStyle,
                    height: '40px',
                    width: '100%',
                    borderColor: props.error ? '#F44336' : isFocused || isHover ? 'rgba(33, 33, 33, 1)' : 'rgba(33, 33, 33, 0.3)'
                }}
                inputClass="phone-input-class"
            />
        </div>
    );
};

export default PhoneInput;
{
    /* <PhoneInputCom
country={'au'}
enableSearch={true}
value={values.phone}
onChange={(phone) => handleChange('phone')(phone)}
placeholder="Your contact number"
inputStyle={{
    width: '100%',
    borderColor: touched.phone && errors.phone ? 'red' : ''
}}
inputClass="phone-input-class"
/> */
}
