import { useQuery } from '@apollo/client';
import { Button, CircularProgress, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import S3Avatar from 'components/avatar/s3Avatar';
import { Checkbox } from 'components/form/checkbox.style';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

interface MyFilterProps {
    title: string;
    id: string;
    query: any; //
    input?: any; //dto
    reach: string[];
    optionMap: string[];
    placeholder: string;
    showPicture: boolean;
    onChange: (e: any, v: any) => void;
    defaultSelected?: any;
    loading?: boolean;
    selectAllIfEmpty?: boolean;
}

function getReach(reach: string[], data: any) {
    if (Array.isArray(reach) && reach.length > 0) {
        let result = data;
        for (const row of reach) {
            result = result?.[row];
        }

        return result;
    }
}

function MultiSelectFilter({
    title,
    query,
    input,
    reach,
    showPicture,
    placeholder,
    optionMap,
    onChange,
    defaultSelected,
    loading = false,
    selectAllIfEmpty = false
}: MyFilterProps) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [options, setOptions] = useState<Array<any>>([]);
    const [selected, setSelected] = useState<Array<any>>(defaultSelected || []);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { data, loading: opitionLoading, error } = useQuery(query, { variables: input });

    // Listing of the dropdown
    useEffect(() => {
        if (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: error?.message ?? 'Something went wrong',
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
        if (!opitionLoading && data) {
            const optionData = getReach(reach, data)?.map((row: any) => {
                return {
                    ...row,
                    displayName: optionMap.reduce((acc, option, index) => {
                        acc += row[option];
                        // Add hyphen only if it's not the last element
                        if (index !== optionMap.length - 1) {
                            acc += '-';
                        }
                        return acc;
                    }, '')
                };
            });
            setOptions(optionData);

            setSelected(defaultSelected || []);

            if (selectAllIfEmpty && defaultSelected.length == 0) {
                setSelected([...optionData]);
            }
        }
    }, [opitionLoading, data, error, defaultSelected]);

    useEffect(() => {
        if (options.length == selected.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [options]);

    const toggleSelection = (option: any) => {
        const index = selected.length === 0 ? -1 : selected?.findIndex((row) => row?._id === option?._id);
        if (index === -1) {
            // not selected
            setSelected((prev) => [...prev, option]);
            onChange({}, [...selected, option]);
        } else {
            // selected case to remove
            setSelected((prev) => prev.filter((row) => row._id !== option._id));
            onChange(
                {},
                selected.filter((row) => row._id !== option._id)
            );
        }
        // setAnchorEl(null);
    };

    const toggleSelectAll = () => {
        setSelectAll((oldValue: boolean) => !oldValue);
        if (selectAll) {
            setSelected([]);
            onChange({}, []);
        } else {
            setSelected([...options]);
            onChange({}, [...options]);
        }
    };

    return (
        <Stack>
            <Button
                sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                variant="outlined"
                size="small"
                onClick={handleClick}
            >
                <Typography variant="body1" fontFamily="Nunito" fontWeight={400} color={theme.palette.grey[600]} mr={1}>
                    {title}:
                </Typography>

                <Typography
                    className="ellipsis"
                    textOverflow="ellipsis"
                    variant="body1"
                    fontFamily="Nunito"
                    fontWeight={400}
                    color={theme.palette.grey[900]}
                >
                    {selected.length === 0 ? placeholder : selected.length}
                </Typography>
            </Button>
            <Menu
                //@ts-ignore
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                {options && (
                    <InfiniteScroll
                        scrollableTarget="customTarget"
                        dataLength={options?.length || 0}
                        next={() => {}}
                        hasMore={false}
                        loader={
                            <Stack height={60} justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Stack>
                        }
                    >
                        <>
                            <MenuItem key="selectAll" onClick={toggleSelectAll}>
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                    <Checkbox checked={selectAll} />
                                    <Typography sx={{ ml: 1, mt: 0.5 }} variant="body2" color={theme.palette.grey[800]} className="fw-400">
                                        Select all
                                    </Typography>
                                </Stack>
                            </MenuItem>

                            {options?.map((option) => (
                                <MenuItem key={option._id} disabled={loading} onClick={() => toggleSelection(option)}>
                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                        <Checkbox checked={selected.some((row) => row._id === option._id)} />
                                        {showPicture && (
                                            <S3Avatar
                                                sx={{ width: '31px', height: '31px' }}
                                                alt={option.firstName}
                                                s3Key={option.profilePicture}
                                                size="small"
                                            />
                                        )}

                                        <Typography
                                            sx={{ ml: 1, mt: 0.5 }}
                                            variant="body2"
                                            color={theme.palette.grey[800]}
                                            className="fw-400"
                                        >
                                            {option.displayName}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </>
                    </InfiniteScroll>
                )}
            </Menu>
        </Stack>
    );
}

export default MultiSelectFilter;
