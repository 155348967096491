import { gql } from '@apollo/client';

export const LIST_ALL_PROMO_CODES_QUERY = gql`
    query ListAllPromoCodes($listAllPromoCodes: PromoCodeListingDTO!) {
        listAllPromoCodes(listAllPromoCodes: $listAllPromoCodes) {
            data {
                _id
                code
                endAfterOccurrence
                endDate
                endType
                promoAmount
                promoDuration
                promoMethod
                status
                title
                createdAt
                promoCodeUsed
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;

export const GET_PROMO_CODE_DETAIL_QUERY = gql`
    query GetPromoCodeDetails($getPromoCodeDetails: PromoCodeIdDTO!) {
        getPromoCodeDetails(getPromoCodeDetails: $getPromoCodeDetails) {
            _id
            code
            createdAt
            endAfterOccurrence
            endDate
            endType
            promoAmount
            promoDuration
            promoMethod
            status
            title
        }
    }
`;
