const lang = {
    IMAGE_MAX_SIZE_VALIDATION: 'Image size must be less than or equal to 1mb.',
    INVALID_FILE_FORMAT: 'Only jpg, jpeg and png is supported.',
    REQUIRED_VALIDATION: '{field} is required field',
    MIN_NAME_VALIDATION: '{field} must be at least of 3 characters',
    MAX_NAME_VALIDATION: '{field} must be at most 30 characters',
    MAX_TITLE_VALIDATION: '{field} must be at most 60 characters',
    MAX_BUSINESS_NAME_VALIDATION: '{field} must be at most 90 characters',

    INVALID_ABN: 'The ABN number must be an eleven digit number',
    INVALID_EMAIL: 'Invalid email',
    INVALID_PHONE: 'Invalid phone number',

    MODAL_CHANGE_STATUS_TITLE: (field: string) => `Change status for ${field}`,
    MODAL_CHANGE_STATUS_MESSAGE: (field: string) => `Are you sure you want to change the status of ${field}`,
    FIELD_REQUIRED: (field: string) => `${field} is a required field`,
    MODAL_UPDATED_SUCCESS: (modal) => `${modal} has been updated successfully.`,
    MODAL_CREATED_SUCCESS: (modal) => `${modal} has been created successfully.`,
    MODAL_DELETE_SUCCESS: (modal) => `${modal} has been deleted successfully.`,
    MODAL_DELETE_MESSAGE: (field: string) => `Are you sure you want to delete ${field}`,
    MODAL_DELETE_TITLE: (field: string) => `Delete ${field}`
};
export default lang;
