import { useTheme } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import Snackbar from 'ui-component/extended/Snackbar';

import Breadcrumb from 'components/breadcrumb';
import Spinner from 'components/spinner';
import lang from 'constants/language';
import { Formik } from 'formik';
import useGQL from 'hooks/useGQL';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ERoutes from 'routes/ERoutes';
import { openSnackbar } from 'store/slices/snackbar';
import { PromoCodeForm } from './promoCodeForm';
import { createPromoCodeValidation } from './validationSchema';

const PromoCodeAddForm = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { open } = useSelector((state: RootState) => state.snackbar);
    const params = useParams();
    const { CREATE_PROMO_CODE, UPDATE_PROMO_CODE, GET_PROMO_CODE_DETAIL } = useGQL();

    const [handleCreatePromoCode] = CREATE_PROMO_CODE();
    const [handleUpdatePromoCode] = UPDATE_PROMO_CODE();

    const [promoCode, setPromoCode] = useState({
        title: '',
        code: '',
        promoAmount: '',
        promoMethod: 'Amount',
        promoDuration: '1',
        endAfterOccurrence: '',
        endDate: '',
        endType: ''
    });

    const { loading } = GET_PROMO_CODE_DETAIL(params.id as string, !params.id, (data) => {
        const discountCodeData = data?.getPromoCodeDetails;
        if (discountCodeData) {
            setPromoCode({
                ...discountCodeData
            });
        }
    });

    const handleSubmitForm = async (values) => {
        try {
            if (params.id) {
                await handleUpdatePromoCode({
                    variables: {
                        updatePromoCode: {
                            promoCodeId: params.id,
                            title: values.title
                        }
                    }
                });
                navigate(ERoutes.promoCodeList);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: lang.MODAL_UPDATED_SUCCESS('Promo code'),
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
            } else {
                await handleCreatePromoCode({
                    variables: {
                        createPromoCode: {
                            ...values
                        }
                    }
                });
                navigate(ERoutes.promoCodeList);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'New promo code created',
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
            }
        } catch (err: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: err?.message,
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    };

    return (
        <>
            <Breadcrumb
                links={[{ name: 'Promo code management', to: ERoutes.promoCodeList }]}
                display={params.id ? promoCode.title : 'Add promo code'}
                isEdit={params.id ? true : false}
                loading={params.id ? (promoCode.title ? false : true) : false}
            />
            {open && <Snackbar />}
            {false ? (
                <Spinner />
            ) : (
                <>
                    <Formik
                        enableReinitialize
                        initialValues={promoCode}
                        validationSchema={createPromoCodeValidation}
                        onSubmit={handleSubmitForm}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setValues }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <PromoCodeForm
                                        values={values}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        setValues={setValues}
                                        isSubmitting={isSubmitting}
                                        paramsId={params.id ? true : false}
                                    />
                                </form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </>
    );
};

export default PromoCodeAddForm;
