export type InviteBusinessType = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    businessName: string;
    abn: string;
};

// table header options
export const headCellsMebershipHistory = [
    {
        id: 'subscriptionName',
        numeric: true,
        label: 'Subcription',
        align: 'center',
        sort: false
    },
    {
        id: 'amount',
        numeric: false,
        label: 'Amount',
        align: 'center',
        sort: false
    },
    {
        id: 'paidDate',
        numeric: false,
        label: 'Date paid',
        align: 'center',
        sort: false
    }
];
