import React from 'react';

// material-ui
import { CardContent, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports

// import ChangePassword from './change-password';
import useConfig from 'hooks/useConfig';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

// assets
import BusinessIcon from '@mui/icons-material/Business';
import GppGoodIcon from '@mui/icons-material/GppGood';
import HistoryIcon from '@mui/icons-material/History';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import { TabsProps } from 'types';
import BusinessProfile from './business-profile';
import UserProfile from './user-profile';
import Membership from './membership';
import MembershipHistory from './membership-history';
// import OtpAuthenticator from './otp-authenticator';

// tabs
function TabPanel({ children, value, index, ...other }: TabsProps) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
    {
        label: 'Business Information',
        icon: <BusinessIcon />,
        caption: 'Business Settings'
    },
    {
        label: 'User Profile',
        icon: <PersonOutlineTwoToneIcon />,
        caption: 'Update Profile'
    },
    {
        label: 'Membership',
        icon: <GppGoodIcon />,
        caption: 'View current membership.'
    },
    {
        label: 'Membership History',
        icon: <HistoryIcon />,
        caption: 'View current membership history.'
    }
];

// ==============================|| PROFILE 2 ||============================== //

const BusinessDetails = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const [value, setValue] = React.useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard title="Business Details" content={false}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} lg={4}>
                            <CardContent>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    orientation="vertical"
                                    variant="scrollable"
                                    sx={{
                                        '& .MuiTabs-flexContainer': {
                                            borderBottom: 'none'
                                        },
                                        '& button': {
                                            color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
                                            minHeight: 'auto',
                                            minWidth: '100%',
                                            py: 1.5,
                                            px: 2,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'flex-start',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            borderRadius: `${borderRadius}px`
                                        },
                                        '& button.Mui-selected': {
                                            color: theme.palette.primary.main,
                                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
                                        },
                                        '& button > svg': {
                                            marginBottom: '0px !important',
                                            marginRight: 1.25,
                                            marginTop: 1.25,
                                            height: 20,
                                            width: 20
                                        },
                                        '& button > div > span': {
                                            display: 'block'
                                        },
                                        '& > div > span': {
                                            display: 'none'
                                        }
                                    }}
                                >
                                    {tabsOption.map((tab, index) => (
                                        <Tab
                                            key={index}
                                            icon={tab.icon}
                                            label={
                                                <Grid container direction="column">
                                                    <Typography variant="subtitle1" color="inherit">
                                                        {tab.label}
                                                    </Typography>
                                                    <Typography component="div" variant="caption" sx={{ textTransform: 'capitalize' }}>
                                                        {tab.caption}
                                                    </Typography>
                                                </Grid>
                                            }
                                            {...a11yProps(index)}
                                        />
                                    ))}
                                </Tabs>
                            </CardContent>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <CardContent
                                sx={{
                                    borderLeft: '1px solid',
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
                                    height: '100%'
                                }}
                            >
                                <TabPanel value={value} index={0}>
                                    <BusinessProfile />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <UserProfile />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Membership />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <MembershipHistory />
                                </TabPanel>
                                {/* <TabPanel value={value} index={1}>
                                    <ChangePassword />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <OtpAuthenticator />
                                </TabPanel> */}
                            </CardContent>
                        </Grid>
                    </Grid>
                    <Divider />
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default BusinessDetails;
