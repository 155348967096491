import { Paper, Skeleton, Tab, Tabs } from '@mui/material';
interface TabSkeletonProps {
    tabs?: Array<number>;
}
export const TabSkeleton = ({ tabs = [1, 2, 3, 4, 5, 6, 7, 8] }: TabSkeletonProps) => {
    return (
        <Paper className="client-tab">
            <Tabs value={false} onChange={() => {}} variant="scrollable" scrollButtons="auto">
                {tabs.map((tab, index) => (
                    <Tab
                        value={tab}
                        key={index}
                        label={
                            <>
                                <Skeleton width={'75px'} height={'70px'} animation={'wave'} />
                            </>
                        }
                    />
                ))}
            </Tabs>
        </Paper>
    );
};
