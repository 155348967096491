// material-ui
import { useState } from 'react';

import { Grid, List, ListItem, ListItemButton, ListItemText, Paper } from '@mui/material';

import Stats from './Stats';

const NpsReport = () => {
    const [selectedItem, setSelectedItem] = useState<number>(0);
    const [tabs] = useState([
        {
            name: 'Stats',
            slug: 'stats',
            value: 0
        }
    ]);

    const renderGraph = () => {
        switch (selectedItem) {
            case 0:
                return <Stats />;

            default:
                break;
        }
    };
    return (
        <Grid container item xs={12} spacing={1} mb={2} mt={0.8}>
            <Grid item xs={12} sm={3} md={2}>
                <Paper>
                    <List component="nav" aria-label="main mailbox folders">
                        {tabs.map((tab, index) => (
                            <ListItem disablePadding>
                                <ListItemButton selected={tab.value === selectedItem} onClick={() => setSelectedItem(tab.value)}>
                                    <ListItemText
                                        primary={tab.name}
                                        primaryTypographyProps={{
                                            color: 'primary',
                                            fontWeight: '400',
                                            variant: 'body2'
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={9} md={10}>
                {renderGraph()}
            </Grid>
        </Grid>
    );
};
export default NpsReport;
