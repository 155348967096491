import { useMutation, useQuery } from '@apollo/client';

import {
    DELETE_BUSINESS_LOCATION_MUTATION,
    INVITE_NEW_BUSINESS,
    SAVE_BUSINESS_DETAILS_MUTATION,
    SAVE_BUSINESS_OWNER_INFO_MUTATION,
    UPDATE_BUSINESS_STATUS_MUTATION,
    UPLOAD_BUSINESS_LOGO_MUTATION,
    UPLOAD_BUSINESS_USER_PICTURE_MUTATION
} from 'grapqhl/business/mutations';
import {
    BUSINESS_SUBSCRIPTION_DETAIL_QUERY,
    BUSINESS_SUBSCRIPTION_HISTORY_QUERY,
    EXPORT_BUSINESSES_QUERY,
    GET_BUSINESS_DETAILS_QUERY,
    GET_DASHBOARD_BUSINESS_COUNT_QUERY,
    GET_DASHBOARD_REVENUE_COUNT_QUERY,
    LIST_ALL_BUSINESSES
} from 'grapqhl/business/queries';
import { LIST_DATA_POINTS_QUERY } from 'grapqhl/dataPoint/queries';
import { GET_FILE_UPLOAD_URL_QUERY, GET_SIGNED_URL_QUERY } from 'grapqhl/fileUpload/queries';
import {
    CHANGE_STATUS_PROMO_CODE_MUTATION,
    CREATE_PROMO_CODE_MUTATION,
    DELETE_PROMO_CODE_MUTATION,
    UPDATE_PROMO_CODE_MUTATION
} from 'grapqhl/promoCode/mutation';
import { LIST_OUTSTANDING_INVOICES_QUERY, PAYMENT_LIST_QUERY, REVENUE_BY_SUBSCRIPTION_QUERY } from 'grapqhl/report/queries';
import { ADD_SURVEY_RESPONSE_MUTATION } from 'grapqhl/survey/mutations';
import { LIST_ALL_NPS_SURVEYS_QUERY } from 'grapqhl/survey/queries';
import {
    GET_ADMIN as ADMIN,
    DELETE_ADMIN as ADMIN_DELETE,
    UPDATE_ADMIN as ADMIN_UPDATE,
    APP_USER_RESET_PASSWORD,
    CHANGE_PASSWORD,
    CODE_VERIFICATION,
    CREATE_MENU as CREATE_MENUS,
    CREATE_PAGE as CREATE_PG,
    CREATE_TAXONOMY as CREATE_TAXONOMYS,
    DELETE_PAGE as DELETE_PG,
    FORGOT_PASSWORD,
    GET_ADMIN_LIST,
    LIST_MENU as GET_LIST_MENU,
    LIST_MENUS as GET_LIST_MENUS,
    LIST_PAGE as GET_LIST_PAGE,
    LIST_PAGES as GET_LIST_PAGES,
    LIST_TAXONOMY as GET_LIST_TAXONOMY,
    GET_PROMO_CODE_DETAIL_QUERY,
    LIST_TAXONOMIES as GET_TAXONOMIES_LIST,
    GET_USER_PROFILE,
    UPLOAD_PROFILE_IMAGE as IMAGE_UPLOAD,
    UPLOAD_MENU_IMAGE as IMG_UPLOAD,
    LIST_ALL_INACTIVE_MEMBERSHIPS_QUERY,
    LIST_ALL_PAID_MEMBERSHIPS_QUERY,
    LIST_ALL_PROMO_CODES_QUERY,
    LIST_ALL_TRIAL_MEMBERSHIPS_QUERY,
    LOGIN,
    DELETE_MENU as MENU_DELETE,
    UPDATE_MENU_STATUS as MENU_STATUS_UPDATE,
    UPDATE_MENU as MENU_UPDATE,
    REGISTER,
    RESEND_OTP_CODE,
    RESET_PASSWORD,
    SEND_RESET_PASSWORD_MAIL,
    DELETE_TAXONOMY as TAXONOMY_DELETE,
    UPDATE_TAXONOMY as TAXONOMY_UPDATE,
    UPDATE_PAGE as UPDATE_PG,
    VALIDATE_OTP,
    VERIFY_TOKEN
} from '../grapqhl';

type GetAdminListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
};

type GetPagesDTO = {
    input: {
        pageMeta: {
            limit?: number;
            skip?: number;
        };
        searchText?: string;
    };
};

type ListPageDTO = {
    listPage: {
        _id: string;
    };
};

type GetMenusDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
};

type GetListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
};

type GetTaxonsDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    taxonomyId: string | undefined;
};

type GetTaxonByTaxonomyIdDTO = {
    taxonomyId: string | undefined;
};

const useGQL = () => {
    const ADMIN_LOGIN = () => useMutation(LOGIN);
    const ADMIN_REGISTER = () => useMutation(REGISTER);
    const FORGOT_PASS = () => useMutation(FORGOT_PASSWORD);
    const RESET_PASS = () => useMutation(RESET_PASSWORD);
    const TOKEN_VERIFY = () => useMutation(VERIFY_TOKEN);
    const SEND_PASSWORD_RESET_MAIL = () => useMutation(SEND_RESET_PASSWORD_MAIL);
    const APP_USER_RESET_PASS = () => useMutation(APP_USER_RESET_PASSWORD);
    const ADMIN_CHANGE_PASSWORD = () => useMutation(CHANGE_PASSWORD);
    const CODE_VERIFY = () => useMutation(CODE_VERIFICATION);
    const RESEND_CODE = () => useMutation(RESEND_OTP_CODE);

    /* admins */
    const GET_ALL_ADMINS = (input: GetAdminListDTO = {}) => useQuery(GET_ADMIN_LIST, { variables: { input } });
    const GET_ADMIN = (id: String) => useQuery(ADMIN, { variables: { id } });
    const UPDATE_ADMIN = () => useMutation(ADMIN_UPDATE);
    const DELETE_ADMIN = () => useMutation(ADMIN_DELETE);
    const GET_ADMIN_PROFILE = () => useQuery(GET_USER_PROFILE);
    const UPLOAD_PROFILE_IMAGE = () => useMutation(IMAGE_UPLOAD);

    const CREATE_PAGE = () => useMutation(CREATE_PG);
    const UPDATE_PAGE = () => useMutation(UPDATE_PG);
    const DELETE_PAGE = () => useMutation(DELETE_PG);
    const LIST_PAGES = (input: GetPagesDTO) =>
        useQuery(GET_LIST_PAGES, { variables: input, notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only' });
    const LIST_PAGE = (input: ListPageDTO) => useQuery(GET_LIST_PAGE, { variables: input, fetchPolicy: 'network-only' });

    const CREATE_MENU = () => useMutation(CREATE_MENUS);
    const LIST_MENUS = (input: GetMenusDTO = {}) => useQuery(GET_LIST_MENUS, { variables: { input }, notifyOnNetworkStatusChange: true });
    const DELETE_MENU = () => useMutation(MENU_DELETE);
    const LIST_MENU = (id: String | undefined) => useQuery(GET_LIST_MENU, { variables: { id }, fetchPolicy: 'network-only' });
    const UPDATE_MENU = () => useMutation(MENU_UPDATE);
    const UPDATE_MENU_STATUS = () => useMutation(MENU_STATUS_UPDATE);
    const UPLOAD_MENU_IMAGE = () => useMutation(IMG_UPLOAD);

    const CREATE_TAXONOMY = () => useMutation(CREATE_TAXONOMYS);
    const UPDATE_TAXONOMY = () => useMutation(TAXONOMY_UPDATE);
    const DELETE_TAXONOMY = () => useMutation(TAXONOMY_DELETE);
    const LIST_TAXONOMY = (id: String | undefined) => useQuery(GET_LIST_TAXONOMY, { variables: { id }, fetchPolicy: 'network-only' });
    const LIST_TAXONOMIES = (input: GetListDTO = {}) =>
        useQuery(GET_TAXONOMIES_LIST, { variables: { input }, notifyOnNetworkStatusChange: true });
    const VALIDATE_AUTH_OTP = () => useMutation(VALIDATE_OTP);

    const GET_SIGNED_URL = (input: { sizes: Array<string>; key: string }, skip = false, onCompleted?: (data: any) => void) =>
        useQuery(GET_SIGNED_URL_QUERY, { variables: { input }, skip, ...(onCompleted ? { onCompleted } : {}) });

    const GET_FILE_UPLOAD_URL = (input: { key: string }, skip = false) =>
        useQuery(GET_FILE_UPLOAD_URL_QUERY, { variables: { input }, skip });

    const LIST_DATA_POINTS = (input: any, skip?: boolean) => useQuery(LIST_DATA_POINTS_QUERY, { variables: input, skip });
    const LIST_PROMO_CODES = (input: any = {}) => useQuery(LIST_ALL_PROMO_CODES_QUERY, { variables: input });
    const CREATE_PROMO_CODE = () => useMutation(CREATE_PROMO_CODE_MUTATION);
    const CHANGE_STATUS_PROMO_CODE = () => useMutation(CHANGE_STATUS_PROMO_CODE_MUTATION);
    const UPDATE_PROMO_CODE = () => useMutation(UPDATE_PROMO_CODE_MUTATION);
    const GET_PROMO_CODE_DETAIL = (promoCodeId: String, skip: boolean = false, onCompleted?: (data: any) => void) =>
        useQuery(GET_PROMO_CODE_DETAIL_QUERY, {
            variables: { getPromoCodeDetails: { promoCodeId: promoCodeId } },
            skip,
            ...(onCompleted ? { onCompleted } : {})
        });
    const DELETE_PROMO_CODE = () => useMutation(DELETE_PROMO_CODE_MUTATION);

    const LIST_BUSINESSES = (input: any = {}) => useQuery(LIST_ALL_BUSINESSES, { variables: input, fetchPolicy: 'network-only' });
    const EXPORT_BUSINESSES = () => useQuery(EXPORT_BUSINESSES_QUERY);
    const UPDATE_BUSINESSS_STATUS = () => useMutation(UPDATE_BUSINESS_STATUS_MUTATION);
    const UPLOAD_BUSINESSS_LOGO = () => useMutation(UPLOAD_BUSINESS_LOGO_MUTATION);
    const SAVE_BUSINESSS_DETAILS = () => useMutation(SAVE_BUSINESS_DETAILS_MUTATION);
    const DELETE_BUSINESSS_LOCATION = () => useMutation(DELETE_BUSINESS_LOCATION_MUTATION);
    const UPLOAD_BUSINESS_USER_PICTURE = () => useMutation(UPLOAD_BUSINESS_USER_PICTURE_MUTATION);
    const SAVE_BUSINESS_OWNER_INFO = () => useMutation(SAVE_BUSINESS_OWNER_INFO_MUTATION);

    const GET_BUSINESS_DETAILS = (input: any, onCompleted?: (data: any) => void) =>
        useQuery(GET_BUSINESS_DETAILS_QUERY, { variables: input, ...(onCompleted ? { onCompleted } : {}) });

    const INVITE_BUSINESS = () => useMutation(INVITE_NEW_BUSINESS);

    const ADD_SURVEY_RESPONSE = () => useMutation(ADD_SURVEY_RESPONSE_MUTATION);
    const LIST_ALL_NPS_SURVEYS = (input, skip: boolean = false, onCompleted?: (data: any) => void) =>
        useQuery(LIST_ALL_NPS_SURVEYS_QUERY, {
            variables: { listNpsSurveys: input },
            skip,
            ...(onCompleted ? { onCompleted } : {})
        });

    const LIST_ALL_PAID_MEMBERSHIPS = (input, skip: boolean = false, onCompleted?: (data: any) => void) =>
        useQuery(LIST_ALL_PAID_MEMBERSHIPS_QUERY, {
            variables: { listPaidMembership: input },
            skip,
            ...(onCompleted ? { onCompleted } : {})
        });
    const LIST_ALL_INACTIVE_MEMBERSHIPS = (input, skip: boolean = false, onCompleted?: (data: any) => void) =>
        useQuery(LIST_ALL_INACTIVE_MEMBERSHIPS_QUERY, {
            variables: { listCancelledMembership: input },
            skip,
            ...(onCompleted ? { onCompleted } : {})
        });
    const LIST_ALL_TRIAL_MEMBERSHIPS = (input, skip: boolean = false, onCompleted?: (data: any) => void) =>
        useQuery(LIST_ALL_TRIAL_MEMBERSHIPS_QUERY, {
            variables: { listTrialMembership: input },
            skip,
            ...(onCompleted ? { onCompleted } : {})
        });

    const GET_DASHBOARD_BUSINESS_COUNT = () => useQuery(GET_DASHBOARD_BUSINESS_COUNT_QUERY);
    const GET_DASHBOARD_REVENUE_COUNT = () => useQuery(GET_DASHBOARD_REVENUE_COUNT_QUERY);

    const LIST_ALL_OUTSTANDING_INVOICES = (input, skip: boolean = false, onCompleted?: (data: any) => void) =>
        useQuery(LIST_OUTSTANDING_INVOICES_QUERY, {
            variables: { input: input },
            skip,
            ...(onCompleted ? { onCompleted } : {})
        });

    const LIST_ALL_PAYMENT = (input, skip: boolean = false, onCompleted?: (data: any) => void) =>
        useQuery(PAYMENT_LIST_QUERY, {
            variables: { input: input },
            skip,
            ...(onCompleted ? { onCompleted } : {})
        });

    const GET_REVENUE_MEMBERSHIP_REPORT = (input: any) =>
        useQuery(REVENUE_BY_SUBSCRIPTION_QUERY, {
            variables: { input }
        });

    const BUSINESS_SUBSCRIPTION_DETAIL = (input) =>
        useQuery(BUSINESS_SUBSCRIPTION_DETAIL_QUERY, { variables: { businessSubscriptionDetail: input } });

    const BUSINESS_SUBSCRIPTION_HISTORY = (input, skip: boolean = false, onCompleted?: (data: any) => void) =>
        useQuery(BUSINESS_SUBSCRIPTION_HISTORY_QUERY, {
            variables: { businessSubscriptionHistory: input },
            skip,
            ...(onCompleted ? { onCompleted } : {})
        });

    return {
        ADMIN_LOGIN,
        ADMIN_REGISTER,
        FORGOT_PASS,
        RESET_PASS,
        TOKEN_VERIFY,
        SEND_PASSWORD_RESET_MAIL,
        APP_USER_RESET_PASS,
        GET_ALL_ADMINS,
        GET_ADMIN,
        UPDATE_ADMIN,
        DELETE_ADMIN,
        GET_ADMIN_PROFILE,
        ADMIN_CHANGE_PASSWORD,
        CREATE_PAGE,
        UPDATE_PAGE,
        DELETE_PAGE,
        LIST_PAGES,
        LIST_PAGE,
        CREATE_MENU,
        LIST_MENUS,
        DELETE_MENU,
        LIST_MENU,
        UPDATE_MENU,
        UPDATE_MENU_STATUS,
        UPLOAD_MENU_IMAGE,
        CREATE_TAXONOMY,
        UPDATE_TAXONOMY,
        DELETE_TAXONOMY,
        LIST_TAXONOMY,
        LIST_TAXONOMIES,
        CODE_VERIFY,
        RESEND_CODE,
        UPLOAD_PROFILE_IMAGE,
        VALIDATE_AUTH_OTP,

        LIST_DATA_POINTS,
        LIST_PROMO_CODES,
        CREATE_PROMO_CODE,
        UPDATE_PROMO_CODE,
        CHANGE_STATUS_PROMO_CODE,
        GET_PROMO_CODE_DETAIL,
        DELETE_PROMO_CODE,

        LIST_BUSINESSES,
        EXPORT_BUSINESSES,
        UPDATE_BUSINESSS_STATUS,
        UPLOAD_BUSINESSS_LOGO,
        SAVE_BUSINESSS_DETAILS,
        GET_BUSINESS_DETAILS,
        DELETE_BUSINESSS_LOCATION,
        UPLOAD_BUSINESS_USER_PICTURE,
        SAVE_BUSINESS_OWNER_INFO,
        GET_SIGNED_URL,
        GET_FILE_UPLOAD_URL,
        INVITE_BUSINESS,

        ADD_SURVEY_RESPONSE,
        LIST_ALL_NPS_SURVEYS,

        LIST_ALL_PAID_MEMBERSHIPS,
        LIST_ALL_INACTIVE_MEMBERSHIPS,
        LIST_ALL_TRIAL_MEMBERSHIPS,

        GET_DASHBOARD_BUSINESS_COUNT,
        GET_DASHBOARD_REVENUE_COUNT,

        LIST_ALL_OUTSTANDING_INVOICES,
        LIST_ALL_PAYMENT,
        GET_REVENUE_MEMBERSHIP_REPORT,
        BUSINESS_SUBSCRIPTION_DETAIL,
        BUSINESS_SUBSCRIPTION_HISTORY
    };
};

export default useGQL;
