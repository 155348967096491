import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import MaintenanceRoutes from './MaintenanceRoutes';
import { useSelector } from 'react-redux';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

const ErrorPage = Loadable(lazy(() => import('../views/pages/maintenance/error')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useSelector((state: any) => state.auth);
    const isRoleAllowed = (allowedRoles: string[]) => allowedRoles.includes(user?.role);

    const renderRoutes = (routes: Array<any>) => {
        return routes.map((route) => {
            if (route.children) {
                return {
                    path: route.path,
                    element: !route.allowedRole || isRoleAllowed(route.allowedRole) ? route.element : <ErrorPage />,
                    children: renderRoutes(route.children)
                };
            } else {
                return !route.allowedRoles || isRoleAllowed(route.allowedRoles)
                    ? {
                          path: route.path,
                          element: route.element
                      }
                    : {
                          path: route.path,
                          element: <ErrorPage />
                      };
            }
        });
    };
    return useRoutes([LoginRoutes, ...renderRoutes([MainRoutes]), ...MaintenanceRoutes]);
}
