import * as Yup from 'yup';
import { HeadCell } from '../types/page';

export const headCells: HeadCell[] = [
    {
        id: 'id',
        label: 'S.No.',
        align: 'center',
        padding: 'none'
    },
    {
        id: 'name',
        label: 'Page title',
        align: 'center',
        padding: 'none'
    },

    {
        id: 'createdAt',
        label: 'Date published',
        align: 'center',
        padding: 'none'
    },

    {
        id: 'updatedAt',
        label: 'Last updated',
        align: 'center',
        padding: 'none'
    },
    {
        id: '6',
        label: 'Action',
        align: 'center',
        padding: 'none'
    }
];

export const pageType = [
    { id: 1, value: 'help', label: 'Help' },
    { id: 2, value: 'terms and conditions', label: 'Terms and conditions' },
    { id: 3, value: 'privacy policy', label: 'Privacy Policy' }
];

export const pageStatus = [
    { id: 1, value: 'active', label: 'Active' },
    { id: 2, value: 'inactive', label: 'Inactive' }
];

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Title must be at least 3 characters')
        .max(150, 'Title must be at most 150 characters')
        .required('Title is a required field'),
    slug: Yup.string().trim().required('Slug is a required field')
});
