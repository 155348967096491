import * as Yup from 'yup';
export const createPromoCodeValidation = Yup.object().shape({
    title: Yup.string().required().max(60).label('Code title'),
    code: Yup.string().min(3).max(15).required().label('Code'),
    promoAmount: Yup.number()
        .when('promoMethod', {
            is: 'Percent',
            then: Yup.number().min(1).max(100).required().label('Value of promo'),
            otherwise: Yup.number().min(1)
        })
        .required()
        .label('Value of promo'),
    promoMethod: Yup.string().required().label('Promo method'),
    promoDuration: Yup.string().required().label('Promo duration'),
    endType: Yup.string().required().label('Promo ends'),
    endDate: Yup.date().when('endType', {
        is: 'date',
        then: Yup.date().nullable().required('End date on is required.'),
        otherwise: Yup.date().nullable()
    }),
    endAfterOccurrence: Yup.number().when('endType', {
        is: 'occurrence',
        then: Yup.number().min(1).nullable().required().label('End after occurance'),
        otherwise: Yup.number().min(1).nullable().label('End after occurance')
    })
});
