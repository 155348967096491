import { gql } from '@apollo/client';

export const LIST_DATA_POINTS_QUERY = gql`
    query ListDataPoints($listDataPointsDto: ListDataPointsDTO!) {
        listDataPoints(listDataPointsDto: $listDataPointsDto) {
            label
            value
        }
    }
`;
