/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */

import TinyMCE from 'components/editors/TinyMCE';
import Spinner from 'components/spinner';
import FailureLoad from 'components/spinner/fail';
import { Formik } from 'formik';
import useSnackbar from 'hooks/common/useSnackbar';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from 'views/userManagement/admins/hooks/useDialogs';
import * as Yup from 'yup';

import { Box, Breadcrumbs, Divider, FormHelperText, Grid, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import { validationSchema } from 'constants/page';
import useGQL from 'hooks/useGQL';
import ERoutes from 'routes/ERoutes';
import { PageType } from 'types/page';
import MainCard from 'ui-component/cards/MainCard';
import Chip from 'ui-component/extended/Chip';
import { SubmitButton } from './page-edit.styles';

const EditPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [editorState, setEditorState] = useState<any>('');
    const [page, setPage] = useState<Partial<PageType>>();

    const { LIST_DATA_POINTS, UPDATE_PAGE, LIST_PAGE } = useGQL();

    const {
        error: pgError,
        loading: pgLoading,
        data: pgData,
        refetch
    } = LIST_PAGE({
        listPage: {
            _id: id as string
        }
    });

    const [chipValues, setChipValues] = useState([]);

    const { data: dataPoint } = LIST_DATA_POINTS({
        listDataPointsDto: {
            type: 'admin-email-template'
        }
    });

    useEffect(() => {
        if (dataPoint?.listDataPoints) {
            setChipValues(dataPoint.listDataPoints);
        }
    }, [dataPoint]);

    const { handleOpenSnackbar } = useSnackbar();
    const [body1, setBody] = useState('<h1>This is Email template<h1>');

    const [handleUpdateTemplate, { data }] = UPDATE_PAGE();
    const [open, setOpen] = useState(false);
    const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (pgData?.listPage) {
            setPage(pgData?.listPage || {});
            setEditorState(pgData?.listPage?.content);
        }
    }, [pgData]);

    useEffect(() => {
        if (data?.updatePage) {
            handleOpenSnackbar({ message: `${data?.updatePage?.message!}. Redirecting...`, alertType: 'success' });
            setTimeout(() => {
                navigate(ERoutes.pageList);
            }, 2000);
        }
    }, [data, navigate]);

    const handleFormSubmit = async (values: any, setSubmitting: (isSubmitting: boolean) => void) => {
        console.log(editorState?.rawHTML, 'rawHtml');
        if (editorState) {
            try {
                const { content, description } = values;
                await handleUpdateTemplate({
                    variables: {
                        input: {
                            _id: page?._id!,
                            content: editorState?.rawHTML ? editorState?.rawHTML : content,
                            description
                        }
                    }
                });
                setSubmitting(false);
            } catch (err: any) {
                handleOpenSnackbar({
                    message: data?.createEmailTemplate?.message! || 'Email template body is not defined',
                    alertType: 'error'
                });
                setSubmitting(false);
            }
        } else {
            handleOpenSnackbar({ message: 'Email template body is not defined', alertType: 'error' });
            setSubmitting(false);
        }
    };
    const getChips = (renderChip: { label: string; value: string }, index: number) => {
        return (
            <Chip
                key={index}
                label={renderChip.label}
                size="medium"
                chipcolor="primary"
                onClick={() => handleChipClick(renderChip.value)}
            />
        );
    };

    const handleChipClick = async (value: string) => {
        await navigator.clipboard.writeText(`[[${value}]]`);
        handleOpenSnackbar({ message: 'Text copied!', alertType: 'success', timeout: 1000 });
    };

    Yup.setLocale({
        string: {
            matches: '${label} must contain only numbers',
            min: '${label} must be at least ${min} characters',
            max: '${label} must be at most ${max} characters',
            email: 'Email address must be valid'
        }
    });
    return (
        <>
            <Paper sx={{ padding: '8px 12px', marginBottom: '16px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="none" href={ERoutes.pageList}>
                        Page Management
                    </Link>
                    <Typography className="BreadCrumbsComponent">{pgData?.listPage?.name}</Typography>
                </Breadcrumbs>
            </Paper>

            {pgLoading || !page ? (
                <Spinner />
            ) : pgError ? (
                <FailureLoad />
            ) : (
                <>
                    <Formik
                        enableReinitialize
                        initialValues={page}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleFormSubmit(values, setSubmitting);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12} lg={6}>
                                    <MainCard title="Edit Page" sx={{ position: 'relative' }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12} sm={10} md={8} lg={5}>
                                                <TextField
                                                    fullWidth
                                                    id="title"
                                                    placeholder="Enter Title"
                                                    value={values.name}
                                                    name="name"
                                                    label="Title"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                    }}
                                                    disabled
                                                />
                                                {touched.name && errors.name && (
                                                    <FormHelperText error id="name-error">
                                                        {errors.name}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12} sm={10} md={8} lg={5}>
                                                <TextField
                                                    fullWidth
                                                    id="slug"
                                                    placeholder="Enter Slug"
                                                    value={values.slug}
                                                    name="slug"
                                                    label="Slug"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                                {touched.slug && errors.slug && (
                                                    <FormHelperText error id="slug-error">
                                                        {errors.slug}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>

                                            <Grid item xs={12} sm={10} md={8} lg={5}>
                                                <TextField
                                                    fullWidth
                                                    id="description"
                                                    placeholder="Enter Description"
                                                    value={values.description}
                                                    name="description"
                                                    label="Description"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {touched.description && errors.description && (
                                                    <FormHelperText error id="description-error">
                                                        {errors.description}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>

                                            {/* <Grid item xs={12} sm={10} md={8} lg={5}>
                                                <Typography variant="body2">
                                                    Placeholders
                                                    <p style={{ fontSize: '10px' }}>
                                                        Note: Use these placeholder according to requirement of your email body{' '}
                                                    </p>
                                                </Typography>
                                                <ChipWrapper>{chipValues?.map((chip, index) => getChips(chip, index))}</ChipWrapper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid> */}

                                            <Grid item xs={12} sm={12} md={12} lg={8}>
                                                <TinyMCE initialValue={editorState} height={250} setEditorState={setEditorState} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ marginTop: '2em' }}>
                                            {' '}
                                            {/* Added spacing between items */}
                                            <Grid container spacing={4}>
                                                <Grid item xs={6} sm={6} md={6} lg={3.8}>
                                                    <Stack direction="row" gap={2}>
                                                        <SubmitButton disabled={isSubmitting} variant="contained" type="submit">
                                                            Update
                                                        </SubmitButton>

                                                        <SubmitButton
                                                            disabled={isSubmitting}
                                                            variant="contained"
                                                            onClick={() => {
                                                                navigate(ERoutes.pageList);
                                                            }}
                                                        >
                                                            Cancel
                                                        </SubmitButton>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </MainCard>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </>
            )}
        </>
    );
};

export default EditPage;
