// third-party
import { FormattedMessage } from 'react-intl';

// assets
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import SubscriptionIcon from '@mui/icons-material/SubscriptionsOutlined';
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
// type
import ERoutes from 'routes/ERoutes';
import { NavItemType } from 'types';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics: IconDeviceAnalytics
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const settings: NavItemType = {
    id: 'settings',
    title: <FormattedMessage id="Settings" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'email-template',
            title: 'Email Template',
            type: 'item',
            icon: EmailIcon,
            url: '/email-template/list',
            breadcrumbs: false
        },

        {
            id: 'promo-code-management',
            title: 'Promo Code Management',
            type: 'item',
            url: ERoutes.promoCodeList,
            icon: SubscriptionIcon,
            breadcrumbs: false
        },

        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            icon: SettingsIcon,
            url: 'settings/edit'
        }
    ]
};

export default settings;
