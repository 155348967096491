import { gql } from '@apollo/client';

export const LOGIN = gql`
    mutation ($input: LoginAdminDTO!) {
        login(input: $input) {
            admin {
                _id
                name
                email
                phone
                role
                status
                enabled2FA
            }
            refreshToken
            accessToken
            expiresBy
            expiresAt
        }
    }
`;

export const REGISTER = gql`
    mutation ($input: CreateAdminDTO!) {
        adminRegister(input: $input) {
            _id
            name
            email
            status
            role
            phone
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation ($input: ForgotPasswordDTO!) {
        forgotPassword(input: $input) {
            message
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation ($input: ResetPasswordDTO!) {
        resetPassword(input: $input)
    }
`;

export const VERIFY_TOKEN = gql`
    mutation ($input: ResetPasswordDTO!) {
        verifyToken(input: $input)
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation ($input: ChangePasswordDTO!) {
        changePassword(input: $input) {
            message
            status
        }
    }
`;

export const REFRESH_TOKEN = gql`
    mutation RefreshToken($refreshToken: String!) {
        refresh(refreshToken: $refreshToken) {
            accessToken
            refreshToken
        }
    }
`;

export const CODE_VERIFICATION = gql`
    mutation ($input: OtpVerificationFor2FADTO!) {
        otpVerificationFor2FA(input: $input) {
            admin {
                _id
                name
                email
                phone
                role
                status
            }
            refreshToken
            accessToken
        }
    }
`;
export const RESEND_OTP_CODE = gql`
    mutation ($input: Resend2FAOtpCodeDTO!) {
        resendOtpCode(input: $input) {
            expiresBy
            expiresAt
        }
    }
`;

export const UPLOAD_PROFILE_IMAGE = gql`
    mutation ($input: uploadProfileImageDTO!) {
        uploadProfileImage(input: $input) {
            url
        }
    }
`;

export const VERIFY_OTP = gql`
    mutation ($token: String!) {
        verifyAuthOTP(token: $token) {
            message
        }
    }
`;

export const VALIDATE_OTP = gql`
    mutation ($input: validateAuthOTPInput!) {
        validateAuthOTP(input: $input) {
            message
            admin {
                _id
                name
                email
                phone
                role
                status
                enabled2FA
            }
            refreshToken
            accessToken
        }
    }
`;

export const AUTH_OTP_DISABLE = gql`
    mutation ($token: String!) {
        disableAuthOTP(token: $token)
    }
`;
