import dayjs from 'utils/date/dayjs';

export const getTimeStringFromDate = (date: Date) => {
    return `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
};

export const getDateValueFromStringTime = (time: string) => {
    return dayjs(time, 'HH:mm').toDate();
};

export const isSameOrBeforeTime = (formTime: string, tillTime: string) => {
    const result = dayjs(formTime).isSameOrBefore(dayjs(tillTime));
    return result;
};

export const isSameOrAfterTime = (formTime: string, tillTime: string) => {
    return dayjs(formTime, 'HH:mm').isSameOrAfter(tillTime);
};

export const formatDateInMonthDayYear = (date: Date) => {
    return dayjs(date).format('MM/DD/YYYY');
};
