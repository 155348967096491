// material-ui
import { useEffect, useState } from 'react';

import { Box, FormControl, Paper, TablePagination, TextField } from '@mui/material';

import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import useGQL from 'hooks/useGQL';
import MainCard from 'ui-component/cards/MainCard';

import LineChart from 'ui-component/third-party/LineChart';
import { Stack } from '@mui/system';
import MultiSelectFilter from 'components/select/multiSelectFilter';
import { GET_ALL_APP_USERS } from 'grapqhl';
import PaymentList from './list';
import { ALL_BUSINESS_LIST_QUERY, MEMBERSHIP_TYPE_LIST_QUERY } from 'grapqhl/report/queries';

const Revenue = () => {
    const [paymentList, setPaymentList] = useState([]);
    const [value, setValue] = useState<any>(new Date());
    const [selectedMembershipType, setSelectedMembershipType] = useState<any>([]);
    const [selectedBusinesses, setSelectedBusinesses] = useState<any>([]);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [fromDate, setFromDate] = useState<any>('');
    const [toDate, setToDate] = useState<any>('');
    const [dateRange, setDateRange] = useState<any>([]);
    const [startDate, endDate] = dateRange;
    const [categories, setCategories] = useState<any>([]);

    const [series, setSeries] = useState<any>([]);
    const [stateValue, setStateValue] = useState<any>({
        planIds: [],
        businessIds: [],
        year: new Date().getFullYear()
    });
    const { LIST_ALL_PAYMENT, GET_REVENUE_MEMBERSHIP_REPORT } = useGQL();

    const { error, loading, data, refetch } = LIST_ALL_PAYMENT({
        skip: page > 0 ? rowsPerPage * page : 0,
        limit: rowsPerPage,
        fromDate: fromDate || null,
        toDate: toDate || null
    });

    const { data: chartData, loading: isLineChartLoading } = GET_REVENUE_MEMBERSHIP_REPORT({
        planId: stateValue.planIds,
        businessId: stateValue.businessIds,
        year: stateValue.year
    });

    useEffect(() => {
        if (data?.getPaymentList?.data) {
            setPaymentList(data?.getPaymentList?.data);
        }

        if (chartData) {
            const membership = chartData?.getNewMembershipByClass.data.map((item) => item.totalAmount);
            setSeries([{ name: 'Total Amount', data: membership }]);
            setCategories(chartData?.getNewMembershipByClass.data.map((item) => item.month));
        }
    }, [data, chartData]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target?.value) {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        }
    };

    const handleChange = (val) => {
        setFromDate(val ? val[0] : '');
        setToDate(val ? val[1] : '');
    };

    const handleChangeFilter = (name, val) => {
        setStateValue((prevValue) => ({
            ...prevValue,
            [name]: val
        }));
    };
    const handleDateRangeChange = (val) => {
        setDateRange(val);
        if (handleChange) {
            handleChange(val);
        }
    };

    const handleChangeYears = (val) => {
        setValue(val);
        if (handleChangeFilter) {
            handleChangeFilter('year', new Date(val).getFullYear());
        }
    };

    const handleMultiSelectChange = (name, val) => {
        switch (name) {
            case 'businessIds':
                setSelectedBusinesses(val);

                if (handleChangeFilter)
                    handleChangeFilter(
                        'businessIds',
                        val.map((item: any) => item._id)
                    );
                break;

            case 'planIds':
                setSelectedMembershipType(val);

                if (handleChangeFilter)
                    handleChangeFilter(
                        'planIds',
                        val.map((item: any) => item._id)
                    );
                break;

            default:
                break;
        }
    };

    return (
        <>
            <MainCard title="Revenue" content={false} sx={{ position: 'relative', mb: '20px' }}>
                <Stack
                    gap={2}
                    direction={{ sm: 'row' }}
                    justifyContent={{ sm: 'space-between' }}
                    alignItems={{ sm: 'center' }}
                    sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
                >
                    <FormControl>
                        <MultiSelectFilter
                            title="Membership"
                            showPicture={false}
                            query={MEMBERSHIP_TYPE_LIST_QUERY}
                            defaultSelected={selectedMembershipType}
                            input={{}}
                            reach={['getSubscriptionPlans', 'subscriptions']}
                            placeholder="All"
                            id="planIds"
                            optionMap={['minimumValue', 'maximumValue']}
                            onChange={(e: any, datass: any) => {
                                handleMultiSelectChange('planIds', datass);
                            }}
                        />
                    </FormControl>

                    <FormControl>
                        <MultiSelectFilter
                            title="Business"
                            showPicture={false}
                            query={ALL_BUSINESS_LIST_QUERY}
                            defaultSelected={selectedBusinesses}
                            input={{}}
                            reach={['listAllBusiness', 'data']}
                            placeholder="All"
                            id="businessIds"
                            optionMap={['businessName']}
                            onChange={(e: any, datas: any) => {
                                handleMultiSelectChange('businessIds', datas);
                            }}
                        />
                    </FormControl>

                    <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MuiDatePicker
                                value={value}
                                onChange={(year) => handleChangeYears(year)}
                                views={['year']}
                                disableFuture
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Stack>
                <LineChart series={series} text="Number of Active Membership over time" categories={categories} />
            </MainCard>

            <MainCard title="Payment List" content={false} sx={{ position: 'relative' }}>
                <Box width={{ xs: '100%', md: '150px', lg: '200px' }} sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
                    <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleDateRangeChange}
                                isClearable
                                placeholderText="Date"
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Box>
                <PaymentList {...{ paymentList, handleRefetch: refetch, loading }} />
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 30]}
                    component={Paper}
                    count={data?.listPaidMembership?.pagination?.total || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default Revenue;
