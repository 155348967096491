import { gql } from '@apollo/client';

export const GET_FILE_UPLOAD_URL_QUERY = gql`
    query GetUploadUrl($input: GetUploadUrlDTO!) {
        getUploadUrl(input: $input) {
            url
        }
    }
`;

export const DELETE_FILE = gql`
    query DeleteS3Object($input: DeleteS3ObjectDTO!) {
        deleteS3Object(input: $input) {
            message
        }
    }
`;

export const GET_SIGNED_URL_QUERY = gql`
    query GetSignedUrl($input: GetSignedUrlDTO!) {
        getSignedUrl(input: $input) {
            original
            small
            medium
        }
    }
`;
