import { HeadCell1 } from 'types';
import * as Yup from 'yup';

// table header options
export const headCells: HeadCell1[] = [
    {
        id: 'codeTitle',
        numeric: true,
        label: 'Code title',
        align: 'center',
        sort: false
    },
    {
        id: 'promoCode',
        numeric: false,
        label: 'Promo code',
        align: 'center',
        sort: true
    },
    {
        id: 'duration',
        numeric: false,
        label: 'Duration',
        align: 'center',
        sort: true
    },
    {
        id: 'promo',
        numeric: false,
        label: 'Promo',
        align: 'center',
        sort: true
    },
    {
        id: 'uses',
        numeric: false,
        label: 'Uses',
        align: 'center',
        sort: true
    },
    {
        id: 'dateCreated',
        numeric: false,
        label: 'Date created',
        align: 'center',
        sort: true
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'center',
        sort: true
    },
    {
        id: 'action',
        numeric: false,
        label: 'Action',
        align: 'center',
        sort: true
    }
];

export const optionYesNo = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
];

export const optionPromoDuration = [
    {
        label: 'once',
        value: 'once'
    },
    {
        label: '1 month',
        value: '1'
    },
    {
        label: '2 months',
        value: '2'
    },
    {
        label: '3 months',
        value: '3'
    },
    {
        label: '6 months',
        value: '6'
    },
    {
        label: '12 months',
        value: '12'
    },
    {
        label: 'indefinite',
        value: 'indefinite'
    }
];

Yup.setLocale({
    string: {
        matches: '${label} must contain only numbers',
        min: '${label} must be at least ${min} characters',
        max: '${label} must be at most ${max} characters'
    }
});
