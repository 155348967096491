// material-ui
import { useEffect, useState } from 'react';

import { Box, Paper, TablePagination } from '@mui/material';

import { StaticSelect } from 'components/select/staticSelect';
import useGQL from 'hooks/useGQL';
import { ArrangementOrder1 } from 'types';
import MainCard from 'ui-component/cards/MainCard';
import SurveyList from './tables';
import TotalResponse from './tables/totalresponse';

const Stats = () => {
    const [surveyList, setSurveyList] = useState([]);
    const [order, setOrder] = useState<ArrangementOrder1>('asc');
    const [orderBy, setOrderBy] = useState<string>('_id');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [count, setCount] = useState<number>(1);
    const [statusFilter, setStatusFilter] = useState<any>(null);
    const [totalResponse, setTotalResponse] = useState<number>(0);
    const [average, setAverage] = useState<number>(0);

    const scoreFilterData = [
        { label: 'Score', value: null },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 }
    ];

    const { LIST_ALL_NPS_SURVEYS } = useGQL();

    const { error, loading, data, refetch } = LIST_ALL_NPS_SURVEYS({
        skip: page > 0 ? rowsPerPage * page : 0,
        limit: rowsPerPage,
        score: statusFilter
    });

    useEffect(() => {
        if (data?.listNpsSurveys?.totalResponse && data?.listNpsSurveys?.average) {
            setTotalResponse(data?.listNpsSurveys?.totalResponse);
            setAverage(data?.listNpsSurveys?.average);
        }

        if (data?.listNpsSurveys?.data) {
            setSurveyList(data?.listNpsSurveys?.data);
        }

        if (error) {
            console.log(error);
        }
    }, [data]);

    if (error) {
        console.log(error);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target?.value) {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        }
    };

    return (
        <>
            <TotalResponse average={average} totalResponse={totalResponse} />
            <MainCard title="Survey Responses" content={false} sx={{ position: 'relative', marginTop: '15px' }}>
                <Box width={{ xs: '100%', md: '150px', lg: '200px' }} sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
                    <StaticSelect
                        name="displayScore"
                        value={statusFilter}
                        onChange={(e) => {
                            setStatusFilter(e?.target?.value);
                        }}
                        placeholder={'Select Status'}
                        options={scoreFilterData}
                        onBlur={() => {}}
                    />
                </Box>
                <SurveyList {...{ surveyList, handleRefetch: refetch, loading }} />
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 30]}
                    component={Paper}
                    count={data?.listNpsSurveys?.pagination?.total || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default Stats;
