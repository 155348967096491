import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

/* in bytes */
const MAX_IMAGE_SIZE = 125000;

const validateType = (event: any, fileType: string) => {
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

    if (event.target.files[0]?.type && fileType === 'IMAGE' && !SUPPORTED_FORMATS.includes(event.target.files[0].type)) {
        dispatch(
            openSnackbar({
                open: true,
                message: 'File type not supported',
                variant: 'alert',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return false;
    }

    if (event.target.files[0]?.size >= MAX_IMAGE_SIZE) {
        dispatch(
            openSnackbar({
                open: true,
                message: 'Image max size must be of 125 KB only.',
                variant: 'alert',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return false;
    }

    return true;
};
export default validateType;
