import { Link, useSearchParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, FormControlLabel, FormHelperText, Grid, OutlinedInput, Radio, RadioGroup, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import Logo from 'ui-component/Logo';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Box } from '@mui/system';
import useGQL from 'hooks/useGQL';
import useSnackbar from 'hooks/common/useSnackbar';
import { InitialNpsSurveyValues } from 'types/survey';

const NpsSurveyForm = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const { handleOpenSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();

    const { ADD_SURVEY_RESPONSE } = useGQL();
    const [handleAddSurveyResponse] = ADD_SURVEY_RESPONSE();

    const businessId = searchParams.get('businessId');

    const handleSubmitForm = async (values: InitialNpsSurveyValues, { resetForm }) => {
        console.log('values: ', values);
        try {
            const response = await handleAddSurveyResponse({
                variables: {
                    input: {
                        ...values,
                        recommendationRating: Number(values.recommendationRating),
                        businessId
                    }
                }
            });
            resetForm();
            handleOpenSnackbar({ message: response?.data?.addNpsSurveyResponse?.message || '', alertType: 'success' });
        } catch (error: any) {
            handleOpenSnackbar({ message: error.message, alertType: 'error' });
        }
    };

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <Logo />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    color={theme.palette.secondary.main}
                                                    gutterBottom
                                                    variant={matchDownSM ? 'h3' : 'h2'}
                                                >
                                                    Share your experience with us!
                                                </Typography>
                                                <Formik
                                                    initialValues={{
                                                        recommendationRating: '',
                                                        feedback: ''
                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        recommendationRating: Yup.number().required('Recommendation Rating is required'),
                                                        feedback: Yup.string()
                                                            .max(255, 'Feedback must be at most 255 characters')
                                                            .required('Feedback is required')
                                                    })}
                                                    onSubmit={handleSubmitForm}
                                                >
                                                    {({
                                                        errors,
                                                        handleBlur,
                                                        handleChange,
                                                        handleSubmit,
                                                        isSubmitting,
                                                        touched,
                                                        values
                                                    }) => (
                                                        <form noValidate onSubmit={handleSubmit}>
                                                            <Typography variant="subtitle1" fontSize="1rem" style={{ marginTop: 20 }}>
                                                                How likely are you to recommend Pogozo to a friend or a colleague?
                                                            </Typography>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="recommendationRating"
                                                                value={values.recommendationRating}
                                                                onChange={handleChange}
                                                            >
                                                                <FormControlLabel value="1" control={<Radio />} label="1" />
                                                                <FormControlLabel value="2" control={<Radio />} label="2" />
                                                                <FormControlLabel value="3" control={<Radio />} label="3" />
                                                                <FormControlLabel value="4" control={<Radio />} label="4" />
                                                                <FormControlLabel value="5" control={<Radio />} label="5" />
                                                            </RadioGroup>
                                                            {touched.recommendationRating && errors.recommendationRating && (
                                                                <FormHelperText error id={`recommendationRating-error`}>
                                                                    {errors.recommendationRating}
                                                                </FormHelperText>
                                                            )}
                                                            <Typography variant="subtitle1" fontSize="1rem" style={{ marginTop: 20 }}>
                                                                Do you have any feedback for us?
                                                            </Typography>
                                                            <OutlinedInput
                                                                id="outlined-adornment-confirm-password"
                                                                aria-describedby="outlined-weight-helper-text"
                                                                placeholder="Feedback (if any)"
                                                                multiline={true}
                                                                rows={2}
                                                                inputProps={{
                                                                    'aria-label': 'weight'
                                                                }}
                                                                style={{ width: '100%' }}
                                                                name="feedback"
                                                                value={values.feedback}
                                                                onChange={handleChange}
                                                            />
                                                            {touched.feedback && errors.feedback && (
                                                                <FormHelperText error id={`feedback-error`}>
                                                                    {errors.feedback}
                                                                </FormHelperText>
                                                            )}

                                                            <Box
                                                                sx={{
                                                                    mt: 1
                                                                }}
                                                            >
                                                                <AnimateButton>
                                                                    <Button
                                                                        disableElevation
                                                                        disabled={isSubmitting}
                                                                        fullWidth
                                                                        size="large"
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                </AnimateButton>
                                                            </Box>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="flex-end" item sx={{ mb: 3 }}>
                                        <Typography color={theme.palette.secondary.main}>Survey by Pogozo Team</Typography>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default NpsSurveyForm;
