// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

import LayersIcon from '@mui/icons-material/Layers';

// type
import ERoutes from 'routes/ERoutes';
import { NavItemType } from 'types';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics: IconDeviceAnalytics
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reportManagement: NavItemType = {
    id: 'Report Management',
    title: <FormattedMessage id="Report Management" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'report',
            title: 'Report',
            type: 'item',
            icon: LayersIcon,
            url: ERoutes.reports,
            breadcrumbs: false
        }
    ]
};

export default reportManagement;
