import { gql } from '@apollo/client';

export const LIST_ALL_PAID_MEMBERSHIPS_QUERY = gql`
    query ListPaidMembership($listPaidMembership: ListMembershipDTO!) {
        listPaidMembership(listPaidMembership: $listPaidMembership) {
            data {
                _id
                businessName
                membershipType {
                    maximumValue
                    minimumValue
                }
                endDate
                startDate
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;

export const LIST_ALL_INACTIVE_MEMBERSHIPS_QUERY = gql`
    query ListCancelledMembership($listCancelledMembership: ListMembershipDTO!) {
        listCancelledMembership(listCancelledMembership: $listCancelledMembership) {
            data {
                _id
                businessName
                startDate
                endDate
                membershipType {
                    maximumValue
                    minimumValue
                }
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;

export const LIST_ALL_TRIAL_MEMBERSHIPS_QUERY = gql`
    query ListTrialMembership($listTrialMembership: ListMembershipDTO!) {
        listTrialMembership(listTrialMembership: $listTrialMembership) {
            data {
                _id
                businessName
                trialStartedAt
                endDate
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;
