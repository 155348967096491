// third-party
import { FormattedMessage } from 'react-intl';

// assets
import PersonIcon from '@mui/icons-material/Person';
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
// type
import { NavItemType } from 'types';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics: IconDeviceAnalytics
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const userManagement: NavItemType = {
    id: 'User Management',
    title: <FormattedMessage id="User Management" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'admins',
            title: 'Admins',
            type: 'item',
            icon: PersonIcon,
            url: '/admin/list',
            breadcrumbs: false,
            roles: ['admin', 'superAdmin']
        }
    ]
};

export default userManagement;
