/* eslint no-underscore-dangle: 0 */

import { Grid, Stack, Typography } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';

type TotalResponseProps = {
    average: number;
    totalResponse: number;
};

const TotalResponse = ({ average, totalResponse }: TotalResponseProps) => {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={6} sm={6}>
                    <MainCard style={{ color: '#212121' }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Stack spacing={1}>
                                    <Typography variant="h5">Total Responses</Typography>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Typography variant="h2">{totalResponse}</Typography>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
                <Grid item xs={12} lg={6} sm={6}>
                    <MainCard style={{ color: '#212121' }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Stack spacing={1}>
                                    <Typography variant="h5">Average Score</Typography>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Typography variant="h2">{average}</Typography>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default TotalResponse;
