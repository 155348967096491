import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Paper, Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { Stack } from '@mui/system';
import slugify from 'slugify';

import { TabSkeleton } from 'components/skeletons/common/tabSkeleton';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

export type TabSectionTabProps = {
    name: string;
    component: () => any;
    afterTabComponents?: () => any;
    hasAccess?: boolean;
};

type TabSectionProps = {
    isLoading: boolean;
    tabs: TabSectionTabProps[];
    mainRoute: string;
};

const TabSection = ({ isLoading, tabs, mainRoute }: TabSectionProps) => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    function accessibleTabs() {
        return tabs.filter((tab) => typeof tab.hasAccess === 'undefined' || tab.hasAccess);
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let queryTab = params.get('tab');

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(`${mainRoute}?tab=${slugify(accessibleTabs()[newValue]?.name?.toLowerCase())}`);
    };

    const getTabIndexByQuery = (tab: string | number) => {
        const index = accessibleTabs().findIndex((t) => slugify(t.name.toLowerCase()) === tab);
        return index > -1 ? index : 0;
    };

    useEffect(() => {
        if (queryTab) {
            setValue(getTabIndexByQuery(queryTab));
        }
    }, [queryTab]);

    return (
        <>
            {isLoading ? (
                <TabSkeleton />
            ) : (
                <Paper className="client-tab">
                    <Stack gap={2} direction={{ md: 'row' }} justifyContent={{ md: ' space-between' }} alignItems={{ md: 'center' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons={false}
                            textColor="primary"
                            className="roundedTab"
                        >
                            {accessibleTabs().map((tab, index) => (
                                <Tab key={index} label={tab.name} {...a11yProps(index)} className="text-transf-none" />
                            ))}
                        </Tabs>
                        {tabs?.[value]?.afterTabComponents && tabs?.[value]?.afterTabComponents?.()}
                    </Stack>
                </Paper>
            )}

            {accessibleTabs().map((tab, index) => (
                <CustomTabPanel key={index} value={value} index={index}>
                    {tab.component()}
                </CustomTabPanel>
            ))}
        </>
    );
};
export default TabSection;
