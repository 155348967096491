import { Box, IconButton } from '@mui/material';
import CrossIcon from 'components/icons/crossIcon';
import Location from 'components/icons/location';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { CSSObjectWithLabel } from 'react-select';

const GoogleAutoComplete = (props: any) => {
    const { placeholder } = props;

    return (
        <>
            <Box className="relative">
                <Box className="icon-container">
                    <Location />
                </Box>
            </Box>
            <Box className="addressWrap" width="100%">
                <GooglePlacesAutocomplete
                    selectProps={{
                        //@ts-ignore
                        value:
                            props?.value?.displayLocation?.length > 0
                                ? {
                                      label: props?.value?.displayLocation,
                                      value: {
                                          description: props?.value?.displayLocation
                                      }
                                  }
                                : '',
                        onChange: async (object) => {
                            props.setFieldValue(props.name, await getLatLngByPlaceId(object?.value.place_id));
                        },
                        placeholder: placeholder,
                        className: props?.myClass,
                        classNamePrefix: 'react-select',
                        styles: {
                            container: () => ({
                                width: '100%'
                            }),
                            input: (provided) =>
                                ({
                                    ...provided,
                                    height: 35,
                                    position: 'relative',
                                    left: 8
                                } as CSSObjectWithLabel),
                            placeholder: (provided) =>
                                ({
                                    ...provided,
                                    color: 'rgba(175, 175, 175, 0.35)',
                                    fontFamily: 'Nunito',
                                    fontSize: 16,
                                    fontWeight: 400,
                                    lineHeight: '23px',
                                    position: 'relative',
                                    left: 8,
                                    letterSpacing: '0.3px'
                                } as CSSObjectWithLabel),
                            indicatorSeparator: () => ({
                                display: 'none'
                            }),
                            indicatorsContainer: () => ({
                                display: 'none'
                            }),
                            control: () => ({
                                border: '1px solid #9e9e9e',
                                borderRadius: 5
                            }),
                            valueContainer: (base) =>
                                ({
                                    ...base,
                                    paddingLeft: 24
                                } as CSSObjectWithLabel),
                            singleValue: (styles) =>
                                ({
                                    ...styles,
                                    fontFamily: 'Nunito',
                                    fontSize: 16,
                                    fontWeight: 600,
                                    lineHeight: '23px',
                                    position: 'relative',
                                    left: 8
                                } as CSSObjectWithLabel)
                        },
                        isClearable: true
                    }}
                    autocompletionRequest={{
                        componentRestrictions: { country: 'au' },
                        types: ['address']
                    }}
                    apiKey={process.env.REACT_APP_GOOGLE_PLACE_SEARCH_API}
                />
                <IconButton
                    onClick={async () => {
                        props.setFieldValue(props.name, '');
                    }}
                >
                    <CrossIcon />
                </IconButton>
            </Box>
        </>
    );
};

export const getLatLngByPlaceId = async (placeId: string) => {
    try {
        const placeDetail = (await geocodeByPlaceId(placeId)) as any;

        if (placeDetail?.length) {
            const placeDetailFromArray = placeDetail[0];
            const lat = placeDetailFromArray?.geometry?.location?.lat();
            const lng = placeDetailFromArray?.geometry?.location?.lng();
            let city = '';
            let state = '';
            let type = 'Point';
            let coordinates = [lat, lng];
            let country = 'Australia';
            let displayLocation: '';
            let postalCode = '';
            let street = '';
            placeDetailFromArray?.address_components.map((addressComponent, i) => {
                if (addressComponent?.types.includes('postal_code')) {
                    postalCode = placeDetailFromArray?.address_components[i].long_name;
                }
                if (addressComponent?.types.includes('locality')) {
                    city = placeDetailFromArray?.address_components[i].long_name;
                }
                if (addressComponent?.types.includes('administrative_area_level_1')) {
                    state = placeDetailFromArray?.address_components[i].long_name;
                }
                if (addressComponent?.types.includes('route')) {
                    street = placeDetailFromArray?.address_components[i].long_name;
                }
            });
            displayLocation = placeDetailFromArray?.formatted_address;

            return {
                type,
                city,
                coordinates,
                country,
                displayLocation,
                postalCode,
                state,
                street
            };
        }
        return;
    } catch (error) {
        return error;
    }
};

export default GoogleAutoComplete;
