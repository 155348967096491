// material-ui
import { Avatar, Button, CircularProgress, FormHelperText, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Spinner from 'components/spinner';
import FailureLoad from 'components/spinner/fail';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useSnackbar from 'hooks/common/useSnackbar';
import useGQL from 'hooks/useGQL';

import { gridSpacing } from 'store/constant';
import { defaultValueProfile } from '../../../constants/business';
// assets
import axios from 'axios';
import S3Avatar from 'components/avatar/s3Avatar';
import PhoneInput from 'components/form/PhoneInput';
import { generateFileNameWithKey } from 'utils/commonHelpers';
import validateType from './validateType';
import { saveUserProfileValidation } from './validationSchema';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const UserProfile = () => {
    const { handleOpenSnackbar } = useSnackbar();
    const { getConfirmation } = useAlertDialog();
    const imageInputRef = useRef<HTMLInputElement>(null);

    const [profileDetails, setProfileDetails] = useState<Partial<typeof defaultValueProfile>>(defaultValueProfile);
    const { GET_BUSINESS_DETAILS, SAVE_BUSINESS_OWNER_INFO, UPLOAD_BUSINESSS_LOGO, GET_FILE_UPLOAD_URL } = useGQL();
    const [profileUrl, setProfileUrl] = useState<any>('');
    const [profileLoading, setProfileLoading] = useState(false);
    const [profileImage, setProfileImage] = useState<any>(null);
    const { refetch: RefetchGetFileUPloadUrl } = GET_FILE_UPLOAD_URL({ key: '' }, true);
    const params = useParams();

    const { error, loading, data, refetch } = GET_BUSINESS_DETAILS({
        getBusinessDetails: {
            businessId: params.id
        }
    });

    const [handleUpdate] = SAVE_BUSINESS_OWNER_INFO();

    useEffect(() => {
        if (data?.getBusinessDetails?.userDetails) {
            let { firstName, lastName, dateOfBirth, email, phone, _id, profilePicture } = data.getBusinessDetails.userDetails;

            if (profilePicture) {
                setProfileLoading(true);
                setProfileUrl(profilePicture);
                setProfileLoading(false);
            }
            setProfileDetails({ firstName, lastName, dateOfBirth, email, phone, _id, profilePicture });
        }
    }, [loading, data?.getBusinessDetails?.userDetails]);
    useEffect(() => {
        refetch();
    }, [data]);

    if (loading) {
        <Spinner />;
    }

    const onChangeProfile = (event) => {
        event.preventDefault();
        if (!validateType(event, 'IMAGE')) {
            return;
        }

        const file = event?.target?.files[0];
        if (file) {
            setProfileImage(file);
        }
    };

    const handleProfileDetailsUpdate = async (values, setSubmitting: (isSubmitting: boolean) => void) => {
        let fileName = '';
        if (profileImage) {
            fileName = generateFileNameWithKey('profilePicture', profileImage?.name);
            const dataRes = await RefetchGetFileUPloadUrl({ input: { key: fileName } });
            const fileUploadUrl = dataRes?.data?.getUploadUrl.url;
            const awsResponse = await axios.put(fileUploadUrl, profileImage, {
                headers: { 'Content-Type': profileImage?.type }
            });
            if (awsResponse.status !== 200) {
                handleOpenSnackbar({ message: 'Something went wrong. Please try again', alertType: 'error' });
                console.log('Error uploading file to s3', awsResponse);
                return;
            }
        }
        try {
            const input = { ...values };

            await handleUpdate({
                variables: {
                    saveBusinessOwnerInfo: {
                        businessId: params.id,
                        dateOfBirth: input.dateOfBirth,
                        email: input.email,
                        firstName: input.firstName,
                        lastName: input.lastName,
                        phone: input.phone,
                        ...(fileName ? { profilePicture: fileName } : {})
                    }
                }
            });
            handleOpenSnackbar({ message: 'Business user profile details updated successfully.', alertType: 'success' });
        } catch (err: any) {
            handleOpenSnackbar({ message: err.message, alertType: 'error' });
            setSubmitting(false);
        }
    };
    //     try {
    //         await handleDeleteLocation({
    //             variables: {
    //                 deleteBusinessLocation: {
    //                     locationId: id,
    //                     businessId: params.id
    //                 }
    //             }
    //         });
    //         refetch();
    //         handleOpenSnackbar({ message: 'Location deleted successfully', alertType: 'success' });

    //         return true;
    //     } catch (err: any) {
    //         handleOpenSnackbar({ message: err.message, alertType: 'error' });
    //     }
    // };
    // const removeFormFields = async (i) => {
    //     const isConfirm = await getConfirmation({
    //         title: 'Delete Location',
    //         message: 'Are you sure you want to delete this location?'
    //     });
    //     if (isConfirm) {
    //         let newFormValues: any = [...data.getBusinessDetails.otherLocations];
    //         if (newFormValues[i]?._id) {
    //             await handleDelete(newFormValues[i]?._id);
    //         } else {
    //             newFormValues.splice(i, 1);
    //             setProfileDetails({ ...data.getBusinessDetails, otherLocations: newFormValues });
    //         }
    //     }
    // };

    return (
        <Grid container spacing={gridSpacing}>
            {loading ? (
                <Spinner />
            ) : error ? (
                <FailureLoad />
            ) : (
                <>
                    <Typography variant="h3" mt={2.5} ml={2}>
                        User profile
                    </Typography>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                {profileImage ? (
                                    <Avatar
                                        alt="UploadProfile"
                                        className="profile-avatar"
                                        src={URL.createObjectURL(profileImage)}
                                        sx={{ height: 80, width: 80 }}
                                    />
                                ) : (
                                    <>
                                        {profileLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <S3Avatar alt="User 1" s3Key={profileUrl} size="small" sx={{ height: 80, width: 80 }} />
                                        )}
                                    </>
                                )}
                            </Grid>
                            <Grid item sm zeroMinWidth>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Button className="profile-upload-button" component="label">
                                                <Typography variant="body1" className="fw-400">
                                                    Change Profile
                                                </Typography>
                                                <Typography variant="subtitle2" className="fw-400">
                                                    Max size limit should be 125kb Max.
                                                </Typography>
                                                <input
                                                    type="file"
                                                    hidden
                                                    name="profilePicture"
                                                    accept=".jpeg, .png, .jpg"
                                                    onChange={(e) => {
                                                        onChangeProfile(e);
                                                    }}
                                                    ref={imageInputRef}
                                                />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FormWrapper>
                        <Formik
                            enableReinitialize
                            initialValues={profileDetails}
                            validationSchema={saveUserProfileValidation}
                            onSubmit={async (values, { setSubmitting }) => {
                                const isConfirm = await getConfirmation({
                                    title: 'Update Details',
                                    message: 'Are you sure you want to update the details ?'
                                });
                                if (isConfirm) {
                                    /* make data sync with update userDTO */
                                    const input = { ...values };
                                    input.profilePicture = profileImage?.name;

                                    delete input._id;

                                    setSubmitting(true);
                                    handleProfileDetailsUpdate(input, setSubmitting);
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3} mb={6}>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel>First name</InputLabel>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter business name"
                                                value={values.firstName}
                                                name="firstName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {touched.firstName && errors.firstName && (
                                                <FormHelperText error id="first-name-error">
                                                    {errors.firstName}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel>Last name</InputLabel>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter business name"
                                                value={values.lastName}
                                                name="lastName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {touched.lastName && errors.lastName && (
                                                <FormHelperText error id="business-name-error">
                                                    {errors.lastName}
                                                </FormHelperText>
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel className="FormInputLabel">Date of birth</InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    inputFormat="MM/DD/YY"
                                                    value={values.dateOfBirth}
                                                    onChange={(value) => setFieldValue('dateOfBirth', value, true)}
                                                    renderInput={(inputParams) => (
                                                        <TextField name="dateOfBirth" fullWidth {...inputParams} />
                                                    )}
                                                />
                                            </LocalizationProvider>

                                            {touched?.dateOfBirth && errors?.dateOfBirth && (
                                                <FormHelperText error id="phoneNumber-error">
                                                    {errors?.dateOfBirth}
                                                </FormHelperText>
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel className="FormInputLabel">Phone number</InputLabel>
                                            <PhoneInput
                                                value={values?.phone}
                                                onChange={(phone) => setFieldValue('phone', phone, true)}
                                                placeholder="Your contact number"
                                                error={!!touched?.phone && !!errors?.phone}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel className="FormInputLabel">Email address</InputLabel>
                                            <TextField
                                                fullWidth
                                                placeholder="Email"
                                                value={values?.email}
                                                type="email"
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {touched?.email && errors?.email && (
                                                <FormHelperText error id="name-error">
                                                    {errors.email}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mt: '1rem' }}>
                                        <Button type="button" variant="contained" color="primary">
                                            Cancel
                                        </Button>
                                        <Button disabled={isSubmitting} type="submit" variant="outlined" color="primary">
                                            Save Changes
                                        </Button>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </FormWrapper>
                </>
            )}
        </Grid>
    );
};

const FormWrapper = styled.div`
    padding: 1rem;
`;

export default UserProfile;
