import { lazy } from 'react';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import BusinessList from 'views/business';
import InviteBusinessForm from 'views/business/forms/invite-business';
import BusinessDetails from 'views/business/profile';
import EditPage from 'views/pageManagement/forms/editPage';
import PromoCodeList from 'views/promoCode';
import PromoCodeAddForm from 'views/promoCode/forms/add-promo-code';
import Reports from 'views/reportManagement';

import ERoutes from './ERoutes';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/default')));

// page management routing
const PageList = Loadable(lazy(() => import('views/pageManagement')));
const AdvancedPageList = Loadable(lazy(() => import('views/pageManagement/advancedPage')));
const AddPage = Loadable(lazy(() => import('views/pageManagement/forms/addPage')));

// menu routing
const TaxonomyList = Loadable(lazy(() => import('views/taxonomy/taxonomyList')));
const ViewTaxonomy = Loadable(lazy(() => import('views/taxonomy/taxonsList')));
const AddTaxon = Loadable(lazy(() => import('views/taxonomy/forms/addTaxonForm')));
const EditTaxon = Loadable(lazy(() => import('views/taxonomy/forms/editTaxonForm')));

// User managemenet routing
const AdminList = Loadable(lazy(() => import('views/userManagement/admins')));
const AdminAdd = Loadable(lazy(() => import('views/userManagement/admins/forms/add-admin')));
const AdminProfile = Loadable(lazy(() => import('views/profile')));
const AdminAccountProfile = Loadable(lazy(() => import('views/profile/user-profile')));
const AdminChangePassword = Loadable(lazy(() => import('views/profile/change-password')));
const UserList = Loadable(lazy(() => import('views/userManagement/users')));
const UserProfile = Loadable(lazy(() => import('views/userManagement/users/profile')));

/* email template */
const EmailTemplateList = Loadable(lazy(() => import('views/emailTemplate')));
const AddEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/addTemplate')));
const EditEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/editTemplate')));

// Mock component
const MockComponent = Loadable(lazy(() => import('views/mock')));

// Error routing
// const ErrorPage = Loadable(lazy(() => import('views/pages/maintenance/error')));

/* settings */
const SettingsList = Loadable(lazy(() => import('views/settings/settingsList')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'default',
            element: <DashboardDefault />
        },

        {
            path: 'taxonomy/list',
            element: <TaxonomyList />
        },
        {
            path: 'taxonomy/view/:taxonomyId',
            element: <ViewTaxonomy />
        },
        {
            path: 'taxon/add/:taxonomyId',
            element: <AddTaxon />
        },
        {
            path: 'taxon/edit/:taxonomyId/:id',
            element: <EditTaxon />
        },

        {
            path: 'page-management/add',
            element: <AddPage />
        },
        {
            path: ERoutes.pageEdit,
            element: <EditPage />
        },
        {
            path: ERoutes.pageList,
            element: <PageList />
        },
        {
            path: 'page-management/add/advance',
            element: <AdvancedPageList />
        },
        {
            path: 'user/list',
            element: <UserList />
        },
        {
            path: 'user/profile/:id',
            element: <UserProfile />
        },

        {
            path: 'admin/list',
            element: <AdminList />
        },
        {
            path: 'admin/add',
            element: <AdminAdd />
        },
        {
            path: 'admin/profile',
            element: <AdminProfile />
        },
        {
            path: 'admin/account-profile',
            element: <AdminAccountProfile />
        },
        {
            path: 'admin/change-password',
            element: <AdminChangePassword />
        },

        {
            path: 'email-template/list',
            element: <EmailTemplateList />
        },
        {
            path: 'email-template/add',
            element: <AddEmailTemplate />
        },
        {
            path: 'email-template/edit/:id',
            element: <EditEmailTemplate />
        },

        {
            path: 'mock',
            element: <MockComponent />
        },
        {
            path: 'settings/edit',
            element: <SettingsList />
        },
        {
            path: ERoutes.promoCodeList,
            element: <PromoCodeList />
        },
        {
            path: ERoutes.promoCodeAdd,
            element: <PromoCodeAddForm />
        },
        {
            path: ERoutes.promoCodeEdit,
            element: <PromoCodeAddForm />
        },
        {
            path: ERoutes.businessList,
            element: <BusinessList />
        },
        {
            path: ERoutes.businessDetail,
            element: <BusinessDetails />
        },
        {
            path: '/businesses/invite',
            element: <InviteBusinessForm />
        },
        {
            path: ERoutes.reports,
            element: <Reports />
        }
    ]
};

export default MainRoutes;
