import React, { useEffect, useState } from 'react';
// material-ui
import {
    // Checkbox,
    Divider,
    Grid,
    TextField,
    // FormGroup,
    // FormControlLabel,
    FormHelperText,
    // Typography,
    MenuItem,
    Button
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import lang from 'constants/language';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import { useNavigate } from 'react-router-dom';
import useGQL from 'hooks/useGQL';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import Snackbar from 'ui-component/extended/Snackbar';
import { RootState } from 'store';
import styled from 'styled-components';
import { firstLetterUppercase, replaceString } from 'utils/commonHelpers';
import Spinner from 'components/spinner';
import { InviteBusinessType } from '../constants/types';

const InviteBusinessForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { open } = useSelector((state: RootState) => state.snackbar);
    const [initialValues] = useState<InviteBusinessType>({ firstName: '', lastName: '', email: '', phone: '', businessName: '', abn: '' });
    const [pageLoading, setPageLoading] = useState(false);
    const { INVITE_BUSINESS } = useGQL();

    const [handleRegister, { loading, error, data }] = INVITE_BUSINESS();

    useEffect(() => {
        if (data?.register) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.register?.message,
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
            setTimeout(() => {
                navigate('/businesses/list');
            }, 2000);
        }
    }, [loading, data]);
    if (pageLoading) {
        return <Spinner />;
    }

    Yup.setLocale({
        string: {
            matches: '${label} must contain only numbers',
            min: '${label} must be at least ${min} characters',
            max: '${label} must be at most ${max} characters',
            email: 'Email address must be valid'
        }
    });
    return (
        <>
            {open && <Snackbar />}
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    firstName: Yup.string()
                        .min(3, 'First name must be at least 3 characters')
                        .max(20, 'First name must be at most 20 characters')
                        .required('First name is required'),
                    lastName: Yup.string()
                        .min(3, 'Last name must be at least 3 characters')
                        .max(20, 'Last name must be at most 20 characters')
                        .required('Last name is required'),
                    email: Yup.string().email().required('Email is required'),
                    phone: Yup.string()
                        .matches(/^\d+$/, 'Phone number must contain only numbers')
                        .min(7, 'Phone number must be at least 7 characters')
                        .max(15, 'Phone number must be at most 15 characters')
                        .required('Phone number is required'),
                    businessName: Yup.string()
                        .min(3, replaceString(lang.MIN_NAME_VALIDATION, '{field}', 'Business'))
                        .max(90, replaceString(lang.MAX_BUSINESS_NAME_VALIDATION, '{field}', 'Business'))
                        .required(replaceString(lang.REQUIRED_VALIDATION, '{field}', 'Business')),
                    abn: Yup.string().matches(/^\d{11}$/, lang.INVALID_ABN)
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await handleRegister({
                            variables: {
                                input: {
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    email: values.email,
                                    phone: values.phone.toString(),
                                    businessName: values.businessName,
                                    abn: values.abn
                                }
                            }
                        });
                        setSubmitting(false);
                        setPageLoading(true);
                    } catch (err: any) {
                        setPageLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: err?.message,
                                anchorOrigin: { horizontal: 'center' },
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                }
                            })
                        );

                        setSubmitting(false);
                    }
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} lg={6}>
                            <MainCard title="Invite New Businesses">
                                <GridContainer container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <InputLabel>First Name</InputLabel>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter first name"
                                            value={values.firstName}
                                            name="firstName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.firstName && errors.firstName && (
                                            <FormHelperText error id="firstName-error">
                                                {firstLetterUppercase(errors.firstName)}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel>Last Name</InputLabel>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter last name"
                                            value={values.lastName}
                                            name="lastName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.lastName && errors.lastName && (
                                            <FormHelperText error id="lastName-error">
                                                {firstLetterUppercase(errors.lastName)}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel>Email</InputLabel>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter email"
                                            value={values.email}
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.email && errors.email && (
                                            <FormHelperText error id="email-error">
                                                {firstLetterUppercase(errors.email)}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel>Phone No.</InputLabel>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter phone number"
                                            value={values.phone}
                                            name="phone"
                                            type="text"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.phone && errors.phone && (
                                            <FormHelperText error id="phone-error">
                                                {firstLetterUppercase(errors.phone)}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel>Business Name</InputLabel>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter business name"
                                            value={values.businessName}
                                            name="businessName"
                                            type="text"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.businessName && errors.businessName && (
                                            <FormHelperText error id="businessName-error">
                                                {firstLetterUppercase(errors.businessName)}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel>ABN</InputLabel>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter abn"
                                            value={values.abn}
                                            name="abn"
                                            type="text"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.abn && errors.abn && (
                                            <FormHelperText error id="abn-error">
                                                {firstLetterUppercase(errors.abn)}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid
                                        sx={{ mt: '10px' }}
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="row"
                                        container
                                        spacing={2}
                                    >
                                        {' '}
                                        <Grid container item justifyContent="flex-start" xs={6}>
                                            {' '}
                                            <Grid sx={{ pl: '20px' }} item>
                                                <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                                                    Invite
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container item justifyContent="flex-end" xs={6}>
                                            {' '}
                                            <Grid item>
                                                {' '}
                                                <Button
                                                    onClick={() => {
                                                        navigate('/businesses/list');
                                                    }}
                                                    type="button"
                                                    variant="contained"
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </GridContainer>
                            </MainCard>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export const GridContainer = styled(({ ...props }) => <Grid {...props} />)`
    width: 100%;
    @media (min-width: 600px) {
        width: 60%;
    }

    @media (min-width: 1024px) {
        width: 50%;
    }

    @media (min-width: 1440px) {
        width: 40%;
    }
`;

export default InviteBusinessForm;
