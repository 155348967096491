import { gql } from '@apollo/client';

export const GET_AUTH_URL = gql`
    query {
        generateOtpAuthUrl {
            base32
            otpAuthUrl
        }
    }
`;
