import { Avatar } from '@mui/material';
import useGQL from 'hooks/useGQL';

const S3Avatar = ({ s3Key, size, ...otherProps }) => {
    const { GET_SIGNED_URL } = useGQL();

    if (s3Key) {
        const { loading: isLoading, data } = GET_SIGNED_URL({ sizes: [size], key: s3Key });
        return <Avatar {...otherProps} src={data?.getSignedUrl?.[size]} />;
    }

    return <Avatar {...otherProps} />;
};

export default S3Avatar;
