const ERoutes = {
    home: '/',
    dashboard: '/dashboard',
    promoCodeList: '/promo-code-management/list',
    promoCodeAdd: '/promo-code-management/add',
    promoCodeEdit: '/promo-code-management/edit/:id',
    businessList: '/businesses/list',
    businessDetail: '/businesses/detail/:id',
    inviteBusiness: '/businesses/invite',
    pageEdit: '/page-management/edit/:id',
    pageList: '/page-management/list',
    reports: '/reports',
    login: '/login'
};
export default ERoutes;
