/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import { Avatar, Box, Button, CircularProgress, FormHelperText, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Spinner from 'components/spinner';
import FailureLoad from 'components/spinner/fail';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useSnackbar from 'hooks/common/useSnackbar';
import useGQL from 'hooks/useGQL';

import { gridSpacing } from 'store/constant';
import { defaultValue } from '../../../constants/business';
// assets
import axios from 'axios';
import S3Avatar from 'components/avatar/s3Avatar';
import PhoneInput from 'components/form/PhoneInput';
import DeleteBorderIcon from 'components/icons/deleteBorderIcon';
import GoogleAutoComplete from 'ui-component/third-party/GooglePlacesAutoComplete';
import { generateFileNameWithKey } from 'utils/commonHelpers';
import validateType from './validateType';
import { saveBusinessValidation } from './validationSchema';

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const BusinessProfile = () => {
    const { handleOpenSnackbar } = useSnackbar();
    const { getConfirmation } = useAlertDialog();
    const imageInputRef = useRef<HTMLInputElement>(null);

    const [businessDetails, setBusinessDetails] = useState<Partial<typeof defaultValue>>(defaultValue);
    const { GET_BUSINESS_DETAILS, SAVE_BUSINESSS_DETAILS, UPLOAD_BUSINESSS_LOGO, DELETE_BUSINESSS_LOCATION, GET_FILE_UPLOAD_URL } =
        useGQL();
    const [profileUrl, setProfileUrl] = useState<any>('');
    const [profileLoading, setProfileLoading] = useState(false);
    const [profileimage, setProfileimage] = useState<any>(null);
    const { refetch: RefetchGetFileUPloadUrl } = GET_FILE_UPLOAD_URL({ key: '' }, true);
    const params = useParams();

    const { error, loading, data, refetch } = GET_BUSINESS_DETAILS({
        getBusinessDetails: {
            businessId: params.id
        }
    });

    const [handleUpdate, { data: update }] = SAVE_BUSINESSS_DETAILS();

    const [handleDeleteLocation, { loading: deleteLoading }] = DELETE_BUSINESSS_LOCATION();

    useEffect(() => {
        if (data?.getBusinessDetails) {
            let { businessName, logo, abn, primaryLocation, otherLocations } = data.getBusinessDetails;

            if (logo) {
                setProfileLoading(true);
                setProfileUrl(logo);
                setProfileLoading(false);
            }
            setBusinessDetails({ businessName, logo, abn, otherLocations, primaryLocation });
        }
    }, [loading, data]);
    useEffect(() => {
        refetch();
    }, [data]);

    if (loading) {
        <Spinner />;
    }

    const onChangeProfile = (event) => {
        event.preventDefault();
        if (!validateType(event, 'IMAGE')) {
            return;
        }

        const file = event?.target?.files[0];
        if (file) {
            setProfileimage(file);
        }
    };

    const handleBusinessDetailsUpdate = async (values, setSubmitting: (isSubmitting: boolean) => void) => {
        let fileName = '';
        if (profileimage) {
            fileName = generateFileNameWithKey('userProfile', profileimage?.name);
            const dataRes = await RefetchGetFileUPloadUrl({ input: { key: fileName } });
            const fileUploadUrl = dataRes?.data?.getUploadUrl.url;
            const awsResponse = await axios.put(fileUploadUrl, profileimage, {
                headers: { 'Content-Type': profileimage?.type }
            });
            if (awsResponse.status !== 200) {
                handleOpenSnackbar({ message: 'Something went wrong. Please try again', alertType: 'error' });
                console.log('Error uploading file to s3', awsResponse);
                return;
            }
        }
        try {
            const input = { ...values };

            await handleUpdate({
                variables: {
                    saveBusinessInfoAndLocation: {
                        businessId: params.id,
                        businessName: input.businessName,
                        abn: input.abn,
                        primaryLocation: input.primaryLocation,
                        otherLocations: input.otherLocations,
                        ...(fileName ? { logo: fileName } : {})
                    }
                }
            });
            handleOpenSnackbar({ message: 'Business details updated successfully.', alertType: 'success' });
        } catch (err: any) {
            handleOpenSnackbar({ message: err.message, alertType: 'error' });
            setSubmitting(false);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            await handleDeleteLocation({
                variables: {
                    deleteBusinessLocation: {
                        locationId: id,
                        businessId: params.id
                    }
                }
            });
            refetch();
            handleOpenSnackbar({ message: 'Location deleted successfully', alertType: 'success' });

            return true;
        } catch (err: any) {
            handleOpenSnackbar({ message: err.message, alertType: 'error' });
        }
    };
    const removeFormFields = async (i) => {
        const isConfirm = await getConfirmation({
            title: 'Delete Location',
            message: 'Are you sure you want to delete this location?'
        });
        if (isConfirm) {
            let newFormValues: any = [...data.getBusinessDetails.otherLocations];
            if (newFormValues[i]?._id) {
                await handleDelete(newFormValues[i]?._id);
            } else {
                newFormValues.splice(i, 1);
                setBusinessDetails({ ...data.getBusinessDetails, otherLocations: newFormValues });
            }
        }
    };

    return (
        <Grid container spacing={gridSpacing}>
            {loading ? (
                <Spinner />
            ) : error ? (
                <FailureLoad />
            ) : (
                <>
                    <Typography variant="h3" mt={2.5} ml={2}>
                        Business details
                    </Typography>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                {profileimage ? (
                                    <Avatar
                                        alt="UploadProfile"
                                        className="profile-avatar"
                                        src={URL.createObjectURL(profileimage)}
                                        sx={{ height: 80, width: 80 }}
                                    />
                                ) : (
                                    <>
                                        {profileLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <S3Avatar alt="User 1" s3Key={profileUrl} size="small" sx={{ height: 80, width: 80 }} />
                                        )}
                                    </>
                                )}
                            </Grid>
                            <Grid item sm zeroMinWidth>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Button className="profile-upload-button" component="label">
                                                <Typography variant="body1" className="fw-400">
                                                    Change business logo
                                                </Typography>
                                                <input
                                                    type="file"
                                                    hidden
                                                    name="profilePicture"
                                                    accept=".jpeg, .png, .jpg"
                                                    onChange={(e) => {
                                                        onChangeProfile(e);
                                                    }}
                                                    ref={imageInputRef}
                                                />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FormWrapper>
                        <Formik
                            enableReinitialize
                            initialValues={businessDetails}
                            validationSchema={saveBusinessValidation}
                            onSubmit={async (values, { setSubmitting }) => {
                                const isConfirm = await getConfirmation({
                                    title: 'Update Details',
                                    message: 'Are you sure you want to update the details ?'
                                });
                                if (isConfirm) {
                                    /* make data sync with update userDTO */
                                    const input = { ...values };
                                    //   input.phone = values.phone ? values.phone.toString() : '';
                                    input.logo = profileimage;

                                    delete input._id;

                                    setSubmitting(true);
                                    handleBusinessDetailsUpdate(input, setSubmitting);
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3} mb={6}>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel>Business name</InputLabel>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter business name"
                                                value={values.businessName}
                                                name="businessName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {touched.businessName && errors.businessName && (
                                                <FormHelperText error id="business-name-error">
                                                    {errors.businessName}
                                                </FormHelperText>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <InputLabel>ABN</InputLabel>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter business abn"
                                                value={values.abn}
                                                name="abn"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {touched.abn && errors.abn && (
                                                <FormHelperText error id="business-abn-error">
                                                    {errors.abn}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {values.primaryLocation && (
                                        <>
                                            {' '}
                                            <Typography variant="h3" mt={2.5}>
                                                Location details
                                            </Typography>
                                            <Box>
                                                <Typography gutterBottom variant="h4" mt={2.5}>
                                                    Primary location
                                                </Typography>

                                                <Grid container mt={2.5} mb={2.5} spacing={2}>
                                                    <Grid item xs={12} md={12} lg={6}>
                                                        <InputLabel>Location name</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            placeholder="Location name"
                                                            value={values.primaryLocation?.name}
                                                            name={`primaryLocation.name`}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                        {/* //@tsignore */}
                                                        {/* {touched.primaryLocation && errors.primaryLocation && (
                                                    <FormHelperText error id="name-error">
                                                        {errors.primaryLocation}
                                                    </FormHelperText>
                                                )} */}
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={6} className="relative">
                                                        <InputLabel>Location</InputLabel>
                                                        <GoogleAutoComplete
                                                            value={values.primaryLocation?.locationAddress}
                                                            placeholder="Location"
                                                            name={`primaryLocation.locationAddress`}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                        {/* {touched.primaryLocation && errors?.primaryLocation && (
                                                    <FormHelperText error id="address-error">
                                                        {errors?.primaryLocation}
                                                    </FormHelperText>
                                                )} */}
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={6}>
                                                        <InputLabel className="FormInputLabel">Email address</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            placeholder="Email"
                                                            value={values.primaryLocation?.email}
                                                            type="email"
                                                            name="primaryLocation.email"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                        {/* {touched?.primaryLocation && errors?.primaryLocation && (
                                                    <FormHelperText error id="name-error">
                                                        {errors.primaryLocation}
                                                    </FormHelperText>
                                                )} */}
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={6}>
                                                        <InputLabel className="FormInputLabel">Phone number</InputLabel>
                                                        <PhoneInput
                                                            value={values.primaryLocation?.phone}
                                                            onChange={(phone) => handleChange('primaryLocation.phone')(phone)}
                                                            placeholder="Your contact number"
                                                            // error={!!touched?.primaryLocation && !!errors?.primaryLocation}
                                                        />

                                                        {/* {touched?.primaryLocation && errors?.primaryLocation && (
                                                    <FormHelperText error id="phoneNumber-error">
                                                        {errors?.primaryLocation}
                                                    </FormHelperText>
                                                )} */}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box mt={2.5} className="otherLocation">
                                                <Typography gutterBottom variant="h4" mt={2.5}>
                                                    Other locations
                                                </Typography>
                                                {values?.otherLocations?.map((element, index) => {
                                                    return (
                                                        <>
                                                            <Grid container spacing={2} mt={2.5} mb={2.5}>
                                                                <Grid item xs={12} md={12} lg={6}>
                                                                    <InputLabel>Location name</InputLabel>
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder="Location name"
                                                                        value={element?.name}
                                                                        name={`otherLocations.${index}.name`}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {/* {touched.otherLocations &&
                                                                touched?.otherLocations[index] &&
                                                                errors.otherLocations &&
                                                                errors?.otherLocations[index] && (
                                                                    <FormHelperText error id="name-error">
                                                                        {errors.otherLocations[index]}
                                                                    </FormHelperText>
                                                                )} */}
                                                                </Grid>
                                                                <Grid item xs={12} md={12} lg={6} className="relative">
                                                                    <InputLabel>Location</InputLabel>
                                                                    <Stack direction="row" className="google-autocomplete">
                                                                        <GoogleAutoComplete
                                                                            value={element.locationAddress}
                                                                            placeholder="Location"
                                                                            name={`otherLocations.${index}.locationAddress`}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                        <DeleteBorderIcon onClick={() => removeFormFields(index)} />
                                                                    </Stack>
                                                                    {/* {touched.otherLocations &&
                                                                touched?.otherLocations[index]?.locationAddress &&
                                                                errors.otherLocations &&
                                                                errors?.otherLocations[index] && (
                                                                    <FormHelperText error id="name-error">
                                                                        {errors.otherLocations[index]}
                                                                    </FormHelperText>
                                                                )} */}
                                                                </Grid>
                                                                <Grid item xs={12} md={12} lg={6}>
                                                                    <InputLabel className="FormInputLabel">Email address</InputLabel>
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder="Email"
                                                                        value={values?.otherLocations?.[index]?.email}
                                                                        type="email"
                                                                        name={`otherLocations.${index}.email`}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />

                                                                    {/* {touched?.otherLocations?.[index]?.email && errors?.otherLocations?.[index] && (
                                                                <FormHelperText error id="name-error">
                                                                    {errors?.otherLocations?.[index]}
                                                                </FormHelperText>
                                                            )} */}
                                                                </Grid>
                                                                <Grid item xs={12} md={12} lg={6}>
                                                                    <InputLabel className="FormInputLabel">Phone number</InputLabel>
                                                                    <PhoneInput
                                                                        value={values?.otherLocations?.[index]?.phone}
                                                                        onChange={(phone) =>
                                                                            handleChange(`otherLocations.${index}.phone`)(phone)
                                                                        }
                                                                        placeholder="Your contact number"
                                                                        // error={
                                                                        //     !!touched?.otherLocations?.[index]?.phone &&
                                                                        //     !!errors?.otherLocations?.[index]
                                                                        // }
                                                                    />

                                                                    {/* {touched?.otherLocations?.[index]?.phone && errors?.otherLocations?.[index] && (
                                                                <FormHelperText error id="phoneNumber-error">
                                                                    {errors?.otherLocations?.[index]}
                                                                </FormHelperText>
                                                            )} */}
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    );
                                                })}
                                            </Box>
                                        </>
                                    )}

                                    <Box sx={{ mt: '1rem' }}>
                                        <Button disabled={isSubmitting} type="submit" variant="outlined" color="primary">
                                            Save Changes
                                        </Button>
                                        {/* <AnimateButton>
                                        </AnimateButton> */}
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </FormWrapper>
                </>
            )}
        </Grid>
    );
};

const FormWrapper = styled.div`
    padding: 1rem;
`;

export default BusinessProfile;
