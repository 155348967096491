/* eslint no-underscore-dangle: 0 */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import Chip from 'ui-component/extended/Chip';

// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';

import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import Spinner from 'components/spinner';

import { headCells } from 'constants/business';
import dayjs from 'dayjs';
import { BusinessStatus } from 'enums/business';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSort } from 'store/slices/table';
import { ActionMenu } from '../actionMenu';

type Sort = { order: string; orderBy: string } | null;
type BusinessTableProps = {
    businessList: any;
    // handleEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, id: string) => void;
    handleRefetch: () => void;
    loading: any;
};

type Order = 'asc' | 'desc';
const BusinessTemplate = ({ businessList /*handleEdit ,*/, handleRefetch, loading }: BusinessTableProps) => {
    const dispatch = useDispatch();
    const {
        sort: { order, orderBy }
    } = useSelector((state: any) => state.table);

    useEffect(() => {
        handleRefetch();
    }, [order, orderBy]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        const setOrder = isAsc ? 'desc' : 'asc';
        dispatch(setSort({ order: setOrder, orderBy: property }));
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                if (headCell.id === 'action') {
                                    return (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.align}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                        >
                                            {headCell.label}
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, headCell.id)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {businessList.length == 0 ? (
                            <TableRow sx={{ borderBottom: 'none' }}>
                                <TableCell align="center"> </TableCell>
                                <TableCell align="center"> </TableCell>
                                <TableCell align="center">
                                    {' '}
                                    <h1>No Items</h1>
                                </TableCell>
                                <TableCell align="center"> </TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {businessList.map((business) => (
                                    <TableRow key={business._id}>
                                        <TableCell align="center">{business.businessName}</TableCell>
                                        <TableCell align="center">{business.abn}</TableCell>
                                        <TableCell align="center">{business.email}</TableCell>
                                        <TableCell align="center">{business.phone}</TableCell>
                                        <TableCell align="center">{dayjs(business.dateJoined).format('D MMMM YYYY')}</TableCell>
                                        <TableCell align="center">
                                            {business.status === BusinessStatus.active && (
                                                <Chip
                                                    label={business.status?.charAt(0).toUpperCase() + business.status?.slice(1)}
                                                    size="medium"
                                                    chipcolor="success"
                                                />
                                            )}

                                            {business.status === BusinessStatus.registered && (
                                                <Chip
                                                    label={business.status?.charAt(0).toUpperCase() + business.status?.slice(1)}
                                                    size="medium"
                                                    chipcolor="secondary"
                                                />
                                            )}

                                            {business.status === BusinessStatus.disabled && (
                                                <Chip
                                                    label={business.status?.charAt(0).toUpperCase() + business.status?.slice(1)}
                                                    size="medium"
                                                    chipcolor="orange"
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            <ActionMenu business={business} handleRefetch={handleRefetch} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default BusinessTemplate;
