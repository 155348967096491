import { gql } from '@apollo/client';

export const LIST_ALL_NPS_SURVEYS_QUERY = gql`
    query ListNpsSurveys($listNpsSurveys: ListNpsSurveyDTO!) {
        listNpsSurveys(listNpsSurveys: $listNpsSurveys) {
            average
            totalResponse

            data {
                _id
                createdAt
                feedback
                recommendationRating
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;
