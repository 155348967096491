import React from 'react';

function CheckboxChecked() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" fill="none" viewBox="0 0 26 25">
            <rect width="25.5" height="25" fill="#00BFB3" fillOpacity="0.5" rx="6"></rect>
            <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.75" d="M9 12.5l2.5 2.5 5-5"></path>
            <rect width="24.5" height="24" x="0.5" y="0.5" stroke="#00BFB3" strokeOpacity="0.5" rx="5.5"></rect>
        </svg>
    );
}

export default React.memo(CheckboxChecked);
