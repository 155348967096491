// assets

// ==============================|| MENU ITEMS ||============================== //

import dashboard from './dashboard';
import userManagement from './userManagement';
// import forms from './forms';
// import elements from './elements';
// import samplePage from './sample-page';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';
import { NavItemType } from 'types';
import businessManagement from './businessManagement';
import contentManagement from './contentManagement';
import reportManagement from './reportManagement';
import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, userManagement, businessManagement, contentManagement, reportManagement, settings]
};

export default menuItems;
