import { HeadCell1 } from 'types';

// table header options
export const headCells: HeadCell1[] = [
    {
        id: 'businessName',
        numeric: false,
        label: 'Business name',
        align: 'left',
        sort: false
    },
    {
        id: 'createdAt',
        numeric: true,
        label: 'Membership Start Date',
        align: 'left',
        sort: false
    },
    {
        id: 'endDate',
        numeric: true,
        label: 'Membership End Date',
        align: 'left',
        sort: false
    },
    {
        id: 'membershipType',
        numeric: false,
        label: 'Membership Type',
        align: 'left',
        sort: false
    }
];
