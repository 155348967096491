function Location() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.84303 4.56789C9.24344 1.16748 14.7566 1.16748 18.157 4.56789C21.5574 7.9683 21.5574 13.4815 18.157 16.8819L16.97 18.0558C16.0952 18.9144 14.96 20.0184 13.5642 21.3679C12.6919 22.2113 11.3081 22.2112 10.436 21.3677L6.9449 17.9718C6.50614 17.541 6.13887 17.1777 5.84303 16.8819C2.44262 13.4815 2.44262 7.9683 5.84303 4.56789ZM17.0963 5.62855C14.2817 2.81392 9.71832 2.81392 6.90369 5.62855C4.08907 8.44317 4.08907 13.0066 6.90369 15.8212L8.39077 17.2887C9.20967 18.0901 10.2391 19.0905 11.4788 20.2895C11.7695 20.5706 12.2308 20.5707 12.5215 20.2896L15.9164 16.9881C16.3854 16.5278 16.7787 16.1388 17.0963 15.8212C19.911 13.0066 19.911 8.44317 17.0963 5.62855ZM12 7.99854C13.6577 7.99854 15.0016 9.34238 15.0016 11.0001C15.0016 12.6578 13.6577 14.0016 12 14.0016C10.3423 14.0016 8.99847 12.6578 8.99847 11.0001C8.99847 9.34238 10.3423 7.99854 12 7.99854ZM12 9.49854C11.1707 9.49854 10.4985 10.1708 10.4985 11.0001C10.4985 11.8294 11.1707 12.5016 12 12.5016C12.8293 12.5016 13.5016 11.8294 13.5016 11.0001C13.5016 10.1708 12.8293 9.49854 12 9.49854Z"
                fill="#00BFB3"
            />
        </svg>
    );
}

export default Location;
