// material-ui
import { Box, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import Spinner from 'components/spinner';
import dayjs from 'dayjs';
import useGQL from 'hooks/useGQL';
import { useParams } from 'react-router-dom';

// ==============================|| MEMBERSHIP - USER PROFILE ||============================== //

const Membership = () => {
    const params = useParams();
    const { BUSINESS_SUBSCRIPTION_DETAIL } = useGQL();

    const { data, loading } = BUSINESS_SUBSCRIPTION_DETAIL({
        businessId: params?.id
    });
    const memberShipData = data?.BusinessSubscriptionDetail;
    const memberShipDetails = [
        {
            title: 'Membership Status',
            value: memberShipData?.businessSubscriptionStatus
        },
        {
            title: 'Membership Type',
            value: memberShipData?.subscription?.status && `${memberShipData?.minimumValue} - ${memberShipData?.maximumValue}`
        },
        {
            title: 'Number of Practitioners',
            value: memberShipData?.subscription?.totalPractitioners
        },
        {
            title: 'Membership Till',
            value: memberShipData?.subscription
                ? dayjs(memberShipData?.subscription.nextPaymentDate).format('DD/MM/YY')
                : memberShipData?.lastDateOfMembership
                ? dayjs(memberShipData?.lastDateOfMembership).format('DD/MM/YY')
                : memberShipData?.trialExpiresOn
                ? dayjs(memberShipData?.trialExpiresOn).format('DD/MM/YY')
                : '-'
        }
    ];
    return loading ? (
        <Spinner />
    ) : (
        <Box border={1} borderColor={'#eee'}>
            <Typography variant="h3" my={2.5} ml={2}>
                Membership Information
            </Typography>
            <Divider />
            <List dense component="div" role="list">
                {memberShipDetails?.map((member: any) => {
                    return (
                        <ListItem role="listitem">
                            <ListItemText
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                    '.MuiListItemText-secondary': {
                                        textTransform: 'capitalize'
                                    }
                                }}
                                primary={member.title}
                                secondary={member.value}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default Membership;
