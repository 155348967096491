import { gql } from '@apollo/client';

export const LIST_PAGES = gql`
    query ListPages($input: GetPagesDTO!) {
        listPages(input: $input) {
            data {
                _id
                content
                createdAt
                description
                name
                updatedAt
            }
            pagination {
                hasNextPage
                total
            }
        }
    }
`;

export const LIST_PAGE = gql`
    query ListPage($listPage: GetGlobalPrivacyDocumentsDTO!) {
        listPage(listPage: $listPage) {
            _id
            content
            createdAt
            description
            name
            updatedAt
            slug
        }
    }
`;
