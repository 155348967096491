import { gql } from '@apollo/client';

export const CREATE_PROMO_CODE_MUTATION = gql`
    mutation CreatePromoCode($createPromoCode: CreatePromoCodeDTO!) {
        createPromoCode(createPromoCode: $createPromoCode) {
            message
        }
    }
`;

export const CHANGE_STATUS_PROMO_CODE_MUTATION = gql`
    mutation ChangeStatusOfPromoCode($input: PromoCodeStatusDTO!) {
        changeStatusOfPromoCode(input: $input) {
            message
        }
    }
`;

export const UPDATE_PROMO_CODE_MUTATION = gql`
    mutation UpdatePromoCode($updatePromoCode: UpdatePromoCodeDTO!) {
        updatePromoCode(updatePromoCode: $updatePromoCode) {
            message
        }
    }
`;

export const DELETE_PROMO_CODE_MUTATION = gql`
    mutation DeletePromoCode($deletePromoCode: PromoCodeIdDTO!) {
        deletePromoCode(deletePromoCode: $deletePromoCode) {
            message
        }
    }
`;
