function CrossIcon({ fillcolor, style }: { fillcolor?: string; style?: any }) {
    return (
        <svg
            style={style}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill={fillcolor ? fillcolor : '#00BFB3'}
            viewBox="0 0 16 16"
        >
            <path
                fill={fillcolor ? fillcolor : '#00BFB3'}
                d="M.397.554L.47.47a.75.75 0 01.976-.073L1.53.47 8 6.94 14.47.47a.75.75 0 111.06 1.06L9.061 8l6.47 6.47a.75.75 0 01.072.976l-.073.085a.75.75 0 01-.976.072l-.084-.072L8 9.06l-6.47 6.47A.75.75 0 01.47 14.47L6.939 8 .469 1.53A.75.75 0 01.398.554L.47.47.397.554z"
            ></path>
        </svg>
    );
}

export default CrossIcon;
