import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link, Skeleton, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type BreadcrumbProps = {
    hasError?: boolean;
    loading?: boolean;
    isEdit: boolean;
    display: string;
    links: { to: string; name: string }[];
};

const renderDisplayText = (isEdit: boolean, display: string, loading?: boolean, hasError?: boolean) => {
    if (isEdit) {
        if (loading) {
            return <Skeleton height={'20px'} width={'70px'} animation={'wave'} />;
        }
        return hasError ? 'Error' : <>{display}</>;
    }

    return <>{display}</>;
};

const Breadcrumb = ({ links, display, hasError, loading, isEdit }: BreadcrumbProps) => {
    return (
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            {links.map((link) => (
                <Link key={link.to} component={RouterLink} to={link.to} underline="none">
                    {link.name}
                </Link>
            ))}
            <Typography className="BreadCrumbsComponent">{renderDisplayText(isEdit, display, loading, hasError)}</Typography>
        </Breadcrumbs>
    );
};
export default Breadcrumb;
