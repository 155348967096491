// material-ui
import { useEffect, useState } from 'react';

import { Box, FormControl, Paper, TablePagination } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import useGQL from 'hooks/useGQL';
import MainCard from 'ui-component/cards/MainCard';
import SMSList from './list';
import { formatDateInMonthDayYear } from 'utils/date/time-parser-helper';

const Sms = () => {
    const [smsList, setSmsList] = useState([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [fromDate, setFromDate] = useState<any>('');
    const [toDate, setToDate] = useState<any>('');
    const [dateRange, setDateRange] = useState<any>([]);
    const [startDate, endDate] = dateRange;

    const { LIST_ALL_OUTSTANDING_INVOICES } = useGQL();

    const { error, loading, data, refetch } = LIST_ALL_OUTSTANDING_INVOICES({
        skip: page > 0 ? rowsPerPage * page : 0,
        limit: rowsPerPage,
        fromDate: fromDate ? formatDateInMonthDayYear(fromDate) : null,
        toDate: toDate ? formatDateInMonthDayYear(toDate) : null
    });

    useEffect(() => {
        if (data?.getSmsCostByBusinessId?.smsList) {
            setSmsList(data?.getSmsCostByBusinessId?.smsList);
        }

        if (error) {
            console.log(error);
        }
    }, [data]);

    if (error) {
        console.log(error);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target?.value) {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        }
    };

    const handleChange = (val) => {
        setFromDate(val ? val[0] : '');
        setToDate(val ? val[1] : '');
    };

    const handleDateRangeChange = (val) => {
        setDateRange(val);
        if (handleChange) {
            handleChange(val);
        }
    };

    return (
        <>
            <MainCard title="List of Outstanding SMS invoices" content={false} sx={{ position: 'relative' }}>
                <Box width={{ xs: '100%', md: '150px', lg: '200px' }} sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
                    <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleDateRangeChange}
                                isClearable
                                placeholderText="Date"
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Box>
                <SMSList {...{ smsList, handleRefetch: refetch, loading }} />
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 30]}
                    component={Paper}
                    count={data?.listPaidMembership?.pagination?.total || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default Sms;
