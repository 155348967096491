// third-party
import { FormattedMessage } from 'react-intl';

// assets
import BusinessIcon from '@mui/icons-material/Business';
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
// type
import ERoutes from 'routes/ERoutes';
import { NavItemType } from 'types';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics: IconDeviceAnalytics
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const businessManagement: NavItemType = {
    id: 'Business Management',
    title: <FormattedMessage id="Business Management" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'businesses',
            title: 'Businesses',
            type: 'item',
            icon: BusinessIcon,
            url: ERoutes.businessList,
            breadcrumbs: false
        }
    ]
};

export default businessManagement;
