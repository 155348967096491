/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { Button, CardContent, Grid, InputAdornment, Paper, TablePagination, TextField } from '@mui/material';

/* gql hooks */
import useGQL from 'hooks/useGQL';

// project imports
import { useDispatch } from 'store';
import MainCard from 'ui-component/cards/MainCard';

/* custom hooks */
import { useDialog } from './hooks/useDialogs';

// assets
import SearchIcon from '@mui/icons-material/Search';

/* variables imports */
import Spinner from 'components/spinner';
import ERoutes from 'routes/ERoutes';
import { ArrangementOrder1 } from 'types';
import useDebouncedSearch from './hooks/useDebounceSearch';
import PromoCodeTemplate from './tables';

// ==============================|| CUSTOMER LIST ||============================== //

const PromoCodeList = () => {
    const [debouncedSearch] = useDebouncedSearch((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target) {
            setSearchText(event.target.value);
        }
    });

    const dispatch = useDispatch();

    /* queries & mutations */
    const { LIST_PROMO_CODES } = useGQL();

    /* local states */
    const [promoCodeList, setPromoCodeList] = useState([]);
    const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();

    const [order, setOrder] = useState<ArrangementOrder1>('asc');
    const [orderBy, setOrderBy] = useState<string>('_id');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [searchText, setSearchText] = useState<string>('');
    const [count, setCount] = useState<number>(1);
    const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({ limit: 5, skip: 0 });

    const { error, loading, data, refetch } = LIST_PROMO_CODES();

    useEffect(() => {
        if (data?.listAllPromoCodes?.data) {
            setPromoCodeList(data.listAllPromoCodes?.data);
        }
        if (error) {
            console.log(error);
        }
    }, [data]);

    if (error) {
        console.log(error);
    }

    useEffect(() => {
        const limit = rowsPerPage;
        const skip = page > 0 ? limit * page : 0;
        setPageMeta({ limit, skip });
        refetch({ listAllPromoCodes: { limit, skip, order, orderBy, searchText } });
    }, [rowsPerPage, page]);

    const handleRefetch = () => {
        refetch({ listAllPromoCodes: { limit: pageMeta?.limit, skip: pageMeta?.skip, order, orderBy, searchText } });
    };

    useEffect(() => {
        handleRefetch();
    }, [searchText]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target?.value) {
            setRowsPerPage(parseInt(event.target.value, 10));
        }
    };

    return (
        <>
            <MainCard title="Promo Code" content={false} sx={{ position: 'relative' }}>
                <Button
                    variant="outlined"
                    component={Link}
                    to={ERoutes.promoCodeAdd}
                    sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
                >
                    Create new
                </Button>
                <CardContent>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={debouncedSearch}
                                placeholder="Search promo code"
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </CardContent>

                {/* table */}
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <PromoCodeTemplate {...{ promoCodeList /*handleEdit */, handleRefetch, loading }} />
                        {/* <AdminEditForm {...{ adminSelected, handleDialogClose, openDialog, listRefetch: handleRefetch }} /> */}

                        {/* table pagination */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 30]}
                            component={Paper}
                            count={data?.listAllPromoCodes?.pagination?.total || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </MainCard>
        </>
    );
};

export default PromoCodeList;
