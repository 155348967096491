import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Spinner from 'components/spinner';
import { headCells } from 'constants/smsList';

type SurveyListProps = {
    smsList: any;
    handleRefetch: () => void;
    loading: any;
};

const SMSList = ({ smsList, handleRefetch, loading }: SurveyListProps) => {
    const {
        sort: { order, orderBy }
    } = useSelector((state: any) => state.table);

    useEffect(() => {
        handleRefetch();
    }, [order, orderBy]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                return (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {smsList.length == 0 ? (
                            <TableRow sx={{ borderBottom: 'none' }}>
                                <TableCell align="left"> </TableCell>
                                <TableCell align="left">
                                    {' '}
                                    <h1>No Items</h1>
                                </TableCell>
                                <TableCell align="left"> </TableCell>
                                <TableCell align="left"> </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {smsList.map((sms) => (
                                    <TableRow key={sms._id}>
                                        <TableCell align="left">{sms.businessName}</TableCell>
                                        <TableCell align="left">{sms.totalSms}</TableCell>
                                        <TableCell align="left">${sms.cost}</TableCell>
                                        <TableCell align="left">{sms?.status}</TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default SMSList;
