/* eslint no-underscore-dangle: 0 */

import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useSnackbar from 'hooks/common/useSnackbar';

import { BusinessStatus } from 'enums/business';
import useGQL from 'hooks/useGQL';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ERoutes from 'routes/ERoutes';
import { openSnackbar } from 'store/slices/snackbar';
import Lang from '../../constants/language';

interface ActionMenuProps {
    business: any;
    handleRefetch: any;
}

export const ActionMenu = (props: ActionMenuProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { business, handleRefetch } = props;
    const { getConfirmation } = useAlertDialog();
    const { handleOpenSnackbar } = useSnackbar();

    const [anchorElAction, setAnchorElAction] = useState(null);
    const [selected, setSelected] = useState('');

    const handleMenuActionClick = (event: any, promoCodeId: string) => {
        setAnchorElAction(event?.currentTarget);
        setSelected(promoCodeId);
    };

    const handleActionClose = () => {
        setAnchorElAction(null);
        setSelected('');
    };

    const { UPDATE_BUSINESSS_STATUS } = useGQL();
    const [handleUpdateBusinessStatus] = UPDATE_BUSINESSS_STATUS();

    const handleChangeStatus = async (businessId: string, status: string) => {
        const isConfirm = await getConfirmation({
            title: Lang.MODAL_CHANGE_STATUS_TITLE('Business'),
            message: Lang.MODAL_CHANGE_STATUS_MESSAGE('Business')
        });

        handleActionClose();
        if (isConfirm) {
            try {
                const businessChangedStatus = await handleUpdateBusinessStatus({
                    variables: {
                        input: {
                            status,
                            businessId
                        }
                    }
                });
                handleRefetch();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: businessChangedStatus?.data?.updateBusinessStatus?.message,
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
            } catch (err: any) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: err?.message,
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };

    return (
        <>
            <IconButton onClick={(e) => handleMenuActionClick(e, business._id)} size="large">
                <MoreVertIcon fontSize="medium" aria-controls="menu-popular-card-1" aria-haspopup="true" />
            </IconButton>
            <Menu
                id="menu-popular-card-1"
                anchorEl={anchorElAction}
                keepMounted
                open={Boolean(anchorElAction)}
                onClose={handleActionClose}
                variant="selectedMenu"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {business.abn && (
                    <MenuItem
                        onClick={() => {
                            navigate(ERoutes.businessDetail.replace(':id', business._id));
                        }}
                    >
                        <ListItemText primary="View" />
                    </MenuItem>
                )}

                {business.status === BusinessStatus.disabled && (
                    <MenuItem onClick={() => handleChangeStatus(business._id, BusinessStatus.active)}>
                        <ListItemText primary="Enable" />
                    </MenuItem>
                )}

                {business.status === BusinessStatus.active && (
                    <MenuItem onClick={() => handleChangeStatus(business._id, BusinessStatus.disabled)}>
                        <ListItemText primary="Disable" />
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};
