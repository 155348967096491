import { gql } from '@apollo/client';

export const UPLOAD_BUSINESS_LOGO_MUTATION = gql`
    mutation UploadBusinessLogo($uploadBusinessLogo: UploadLogoDTO!) {
        uploadBusinessLogo(uploadBusinessLogo: $uploadBusinessLogo) {
            message
        }
    }
`;

export const UPDATE_BUSINESS_STATUS_MUTATION = gql`
    mutation UpdateBusinessStatus($input: UpdateBusinessStatusDTO!) {
        updateBusinessStatus(input: $input) {
            message
        }
    }
`;

export const SAVE_BUSINESS_DETAILS_MUTATION = gql`
    mutation SaveBusinessInfoAndLocation($saveBusinessInfoAndLocation: SaveBusinessInfoDTO!) {
        saveBusinessInfoAndLocation(saveBusinessInfoAndLocation: $saveBusinessInfoAndLocation) {
            message
        }
    }
`;

export const DELETE_BUSINESS_LOCATION_MUTATION = gql`
    mutation DeleteBusinessLocation($deleteBusinessLocation: LocationIdDto!) {
        deleteBusinessLocation(deleteBusinessLocation: $deleteBusinessLocation) {
            message
        }
    }
`;

export const INVITE_NEW_BUSINESS = gql`
    mutation Register($input: InviteBusinessDTO!) {
        register(input: $input) {
            message
        }
    }
`;

export const UPLOAD_BUSINESS_USER_PICTURE_MUTATION = gql`
    mutation UploadBusinessUserPicture($uploadBusinessUserPicture: UploadBusinessUserPictureDTO!) {
        uploadBusinessUserPicture(uploadBusinessUserPicture: $uploadBusinessUserPicture) {
            message
        }
    }
`;

export const SAVE_BUSINESS_OWNER_INFO_MUTATION = gql`
    mutation SaveBusinessOwnerInfo($saveBusinessOwnerInfo: SaveBusinessOwnerInfoDTO!) {
        saveBusinessOwnerInfo(saveBusinessOwnerInfo: $saveBusinessOwnerInfo) {
            message
        }
    }
`;
