function DeleteBorderIcon(props: any) {
    return (
        <svg
            className="googleautocomplete-icon"
            cursor={'pointer'}
            onClick={props.onClick}
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" fill="white" />
            <path
                d="M26 21H30C30 19.8954 29.1046 19 28 19C26.8954 19 26 19.8954 26 21ZM24.5 21C24.5 19.067 26.067 17.5 28 17.5C29.933 17.5 31.5 19.067 31.5 21H37.25C37.6642 21 38 21.3358 38 21.75C38 22.1642 37.6642 22.5 37.25 22.5H35.9309L34.7589 34.6112C34.5729 36.5334 32.9575 38 31.0263 38H24.9737C23.0425 38 21.4271 36.5334 21.2411 34.6112L20.0691 22.5H18.75C18.3358 22.5 18 22.1642 18 21.75C18 21.3358 18.3358 21 18.75 21H24.5ZM26.5 25.75C26.5 25.3358 26.1642 25 25.75 25C25.3358 25 25 25.3358 25 25.75V33.25C25 33.6642 25.3358 34 25.75 34C26.1642 34 26.5 33.6642 26.5 33.25V25.75ZM30.25 25C30.6642 25 31 25.3358 31 25.75V33.25C31 33.6642 30.6642 34 30.25 34C29.8358 34 29.5 33.6642 29.5 33.25V25.75C29.5 25.3358 29.8358 25 30.25 25ZM22.7342 34.4667C22.8458 35.62 23.815 36.5 24.9737 36.5H31.0263C32.185 36.5 33.1542 35.62 33.2658 34.4667L34.4239 22.5H21.5761L22.7342 34.4667Z"
                fill="#212121"
            />
            <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" stroke="#00BFB3" />
        </svg>
    );
}

export default DeleteBorderIcon;
