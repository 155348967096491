import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';

const promoOptions = [
    {
        value: 'Percent',
        label: '%'
    },
    {
        value: 'Amount',
        label: '$'
    }
];

interface PromoSelectProps {
    setValue: (string) => void;
    value: string;
}

const useStyles = makeStyles((theme) => ({
    iconOpen: {
        transform: 'rotate(180deg)'
    }
}));
export const PromoSelect = (props: PromoSelectProps) => {
    const classes = useStyles();
    const { setValue } = props;

    return (
        <>
            <Select
                IconComponent={ExpandMoreIcon}
                classes={{
                    iconOpen: classes.iconOpen
                }}
                value={props.value}
                sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        border: 0
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 0
                    }
                }}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
            >
                {promoOptions.map((promo, index) => (
                    <MenuItem key={index} value={promo.value}>
                        {promo.label}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};
