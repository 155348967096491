import { HeadCell1 } from 'types';

// table header options
export const headCells: HeadCell1[] = [
    {
        id: 'businessName',
        numeric: false,
        label: 'Business name',
        align: 'left',
        sort: false
    },
    {
        id: 'paymentType',
        numeric: true,
        label: 'Payment Type',
        align: 'left',
        sort: false
    },
    {
        id: 'amount',
        numeric: true,
        label: 'Amount Paid',
        align: 'left',
        sort: false
    },
    {
        id: 'paidDate',
        numeric: false,
        label: 'Payment Date',
        align: 'left',
        sort: false
    }
];
