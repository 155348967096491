import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import InputLabel from 'ui-component/extended/Form/InputLabel';

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { PromoSelect } from 'components/select/promoCodeSelect';
import { StaticSelect } from 'components/select/staticSelect';
import { optionPromoDuration } from 'constants/promoCodeManagement';
import { useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';

export const PromoCodeForm = (props: any) => {
    const browserParams = useParams();
    const isEdit = browserParams?.id ? true : false;
    const { values, handleBlur, handleChange, touched, errors, setFieldValue, isSubmitting, setValues, paramsId } = props;

    return (
        <MainCard title="Add Promo Code">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <InputLabel>Code title</InputLabel>
                    <TextField
                        fullWidth
                        placeholder="Title"
                        className="FormInputLabel"
                        value={values.title}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                    ></TextField>
                    {touched.title && errors.title && (
                        <FormHelperText error id="title-error">
                            {errors.title}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel>Code</InputLabel>
                    <TextField
                        fullWidth
                        placeholder="Promo code"
                        className="FormInputLabel"
                        value={values.code}
                        name="code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={paramsId}
                    ></TextField>
                    {touched.code && errors.code && (
                        <FormHelperText error id="code-error">
                            {errors.code}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel>Value of promo</InputLabel>
                    <TextField
                        type="number"
                        fullWidth
                        placeholder={values.promoMethod == 'Amount' ? 'Amount' : 'Percentage'}
                        className="FormInputLabel salutationSelectInput hideSpinButton"
                        value={values.promoAmount}
                        name="promoAmount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={paramsId}
                        InputProps={{
                            startAdornment: (
                                <PromoSelect
                                    value={values.promoMethod}
                                    setValue={(newValue) => {
                                        setValues({
                                            ...values,
                                            promoMethod: newValue
                                        });
                                    }}
                                />
                            )
                        }}
                    ></TextField>
                    {touched.promoAmount && errors.promoAmount && (
                        <FormHelperText error id="promoAmount-error">
                            {errors.promoAmount}
                        </FormHelperText>
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    <InputLabel>Promo code duration</InputLabel>
                    <StaticSelect
                        options={optionPromoDuration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={'Select'}
                        value={values.promoDuration}
                        name="promoDuration"
                        setFieldValue={setFieldValue}
                        disabled={paramsId ? true : false}
                    ></StaticSelect>
                    {touched.promoDuration && errors.promoDuration && (
                        <FormHelperText error id="promoDuration-error">
                            {errors.promoDuration}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Stack className="promoEndSection">
                        <FormControl>
                            <FormLabel id="promo-code-end-radio">
                                <Typography color="#616161" fontWeight="500">
                                    Promo ends
                                </Typography>
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="promo-code-end-radio"
                                defaultValue="never"
                                name="radio-buttons-group"
                                value={values.endType}
                                onChange={(e) => {
                                    setFieldValue('endType', e.currentTarget.value);
                                    setFieldValue('endDate', null);
                                    setFieldValue('endAfterOccurrence', null);
                                    if (e.currentTarget.value === 'occurrence' && isEdit) {
                                        setFieldValue('endAfterOccurrence', values.endAfterOccurrence);
                                    }
                                    if (e.currentTarget.value === 'occurrence' && isEdit) {
                                        setFieldValue('endDate', values.endDate);
                                    }
                                }}
                            >
                                <FormControlLabel disabled={paramsId} value="never" control={<Radio color="secondary" />} label="Never" />
                                <Stack>
                                    <Stack direction="row" alignItems={'center'}>
                                        <FormControlLabel
                                            disabled={paramsId}
                                            value="date"
                                            control={<Radio color="secondary" />}
                                            label="On"
                                        />
                                        <LocalizationProvider name="dueDate" dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                inputFormat="MM/DD/YYYY"
                                                minDate={new Date()}
                                                value={values.endDate}
                                                disabled={
                                                    values.endType === 'never' || values.endType === 'occurence' || values.endType === ''
                                                }
                                                onChange={(e) => {
                                                    setFieldValue('endDate', e);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: 'June 6, 2023'
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                    {touched.endDate && errors.endDate && (
                                        <FormHelperText sx={{ marginLeft: 0 }} error id="endDate-error">
                                            {errors.endDate}
                                        </FormHelperText>
                                    )}
                                </Stack>
                                <Stack>
                                    <Stack direction="row" alignItems={'center'}>
                                        <FormControlLabel
                                            disabled={paramsId}
                                            value="occurrence"
                                            control={<Radio color="secondary" />}
                                            label="After"
                                        />
                                        <TextField
                                            fullWidth
                                            className="hideSpinButton"
                                            type="number"
                                            value={values.endAfterOccurrence || ''}
                                            placeholder="12 occurences"
                                            inputProps={{ min: 0 }}
                                            name="After"
                                            onBlur={handleBlur}
                                            disabled={values.endType === 'never' || values.endType === 'date' || values.endType === ''}
                                            onChange={(e) => {
                                                setFieldValue('endAfterOccurrence', parseInt(e.target.value));
                                            }}
                                        />
                                    </Stack>
                                    {touched.endAfterOccurrence && errors.endAfterOccurrence && (
                                        <FormHelperText sx={{ marginLeft: 0 }} error id="endAfterOccurrence-error">
                                            {errors.endAfterOccurrence}
                                        </FormHelperText>
                                    )}
                                    {touched.endType && errors.endType && (
                                        <FormHelperText sx={{ marginLeft: 0 }} error id="endType-error">
                                            {errors.endType}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid sx={{ pl: '20px' }} item>
                    <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                        {paramsId ? 'Update' : 'Create'}
                    </Button>
                </Grid>
            </Grid>
        </MainCard>
    );
};
