export const truncateString = (str, num) => {
    if (str.length > num) {
        return `${str.slice(0, num)}...`;
    }
    return str;
};

export const firstLetterUppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (sizeInKb, si = false, dp = 1) => {
    let sizeInBytes = sizeInKb * 1000; // convert to bytes
    const thresh = si ? 1000 : 1024;

    if (Math.abs(sizeInBytes) < thresh) {
        return sizeInBytes + ' B';
    }

    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        sizeInBytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(sizeInBytes) * r) / r >= thresh && u < units.length - 1);

    return sizeInBytes.toFixed(dp) + ' ' + units[u];
};
export const generateFileNameWithKey = (path, name) => {
    const split = name.split('.');
    const trimmedName = split[0].slice(0, 10).replace(/[^a-zA-Z0-9]/g, '') || new Date().getTime();
    return `${path}/${new Date().getTime()}_name_${trimmedName}.${split[split.length - 1]}`;
};
export const extractFileName = (name) => {
    const split = name.split('_');
    return split[split.length - 1];
};
export const replaceString = (str: string, replaceTo: string, replaceBy: string) => {
    return str.replace(replaceTo, replaceBy);
};

export const validateSrtingLength = (str: string | undefined, max: number, min: number) => {
    if (!str) {
        return false;
    }
    if (max) {
        if (str.length <= max) {
            return true;
        }
        return false;
    }
    if (min) {
        if (str.length >= min) {
            return true;
        }
        return false;
    }
    return false;
};
export const extractContent = (htmlString) => {
    return htmlString.replace(/<[^>]+>/g, '');
};
export const ellipsisHandler = (text: string, maxChar: number): string => {
    if (text?.length > maxChar) {
        return text.substring(0, maxChar) + '...';
    } else {
        return text;
    }
};
