import React from 'react';

function CheckboxUnchecked() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" fill="none" viewBox="0 0 26 25">
            <rect width="24.5" height="24" x="0.5" y="0.5" fill="#fff" rx="5.5"></rect>
            <rect width="24.5" height="24" x="0.5" y="0.5" stroke="#303637" rx="5.5"></rect>
        </svg>
    );
}

export default React.memo(CheckboxUnchecked);
