import { HeadCell1 } from 'types';

// table header options
export const headCells: HeadCell1[] = [
    {
        id: 'submittedDate',
        numeric: false,
        label: 'Submitted Date',
        align: 'left',
        sort: false
    },
    {
        id: 'score',
        numeric: true,
        label: 'Score',
        align: 'left',
        sort: false
    },
    {
        id: 'remarks',
        numeric: false,
        label: 'Remarks',
        align: 'left',
        sort: false
    }
];
