// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

import NewspaperIcon from '@mui/icons-material/Newspaper';

// type
import ERoutes from 'routes/ERoutes';
import { NavItemType } from 'types';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics: IconDeviceAnalytics
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const contentManagement: NavItemType = {
    id: 'Content Management',
    title: <FormattedMessage id="Content Management" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'page-management',
            title: 'Pages',
            type: 'item',
            icon: NewspaperIcon,
            url: ERoutes.pageList,
            breadcrumbs: false
        }
    ]
};

export default contentManagement;
