/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useEffect, useState } from 'react';

// material-ui
import { Box, Button, CardContent, InputAdornment, Paper, Stack, TablePagination, TextField } from '@mui/material';

/* gql hooks */
import useGQL from 'hooks/useGQL';

// project imports
import { useDispatch } from 'store';
import MainCard from 'ui-component/cards/MainCard';

/* custom hooks */

// assets
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';

/* variables imports */
import Spinner from 'components/spinner';
import { ArrangementOrder1 } from 'types';

import useDebouncedSearch from 'hooks/common/useDebounceSearch';
import { useDialog } from './hooks/useDialogs';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import datePickerIcon from 'components/icons/datePickerIcon';
import { StaticSelect } from 'components/select/staticSelect';
import { useNavigate } from 'react-router-dom';
import BusinessTemplate from './tables';
import { EXPORT_BUSINESSES_QUERY } from 'grapqhl/business/queries';
import { useLazyQuery } from '@apollo/client';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// ==============================|| CUSTOMER LIST ||============================== //

const BusinessList = () => {
    const [debouncedSearch] = useDebouncedSearch((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target) {
            setSearchText(event.target.value);
            setPage(0);
        }
    });

    const dispatch = useDispatch();

    /* queries & mutations */
    const { LIST_BUSINESSES, EXPORT_BUSINESSES } = useGQL();

    /* local states */
    const [businessList, setBusinessList] = useState([]);
    const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();
    const navigate = useNavigate();

    const [order, setOrder] = useState<ArrangementOrder1>('asc');
    const [orderBy, setOrderBy] = useState<string>('_id');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [searchText, setSearchText] = useState<any>();
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [count, setCount] = useState<number>(1);
    const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({ limit: 5, skip: 0 });
    const [statusFilter, setStatusFilter] = useState<any>('');
    const businessStatusFilterData = [
        { label: 'Select', value: '' },
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' }
    ];
    const [handleExport, { loading: exporting }] = useLazyQuery(EXPORT_BUSINESSES_QUERY);

    const { error, loading, data, refetch } = LIST_BUSINESSES({
        input: {
            pageMeta: {
                skip: page > 0 ? rowsPerPage * page : 0,
                limit: rowsPerPage
            },
            searchText,
            filters: {
                status: statusFilter || null,
                dateJoinedRange: {
                    startDate: startDate || null,
                    endDate: endDate || null
                }
            }
        }
    });

    useEffect(() => {
        if (data?.listBusiness?.data) {
            setBusinessList(data.listBusiness?.data);
        }
        if (error) {
            console.log(error);
        }
    }, [data]);

    if (error) {
        console.log(error);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target?.value) {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        }
    };

    const handleExportBusiness = async () => {
        await handleExport().then((result) => {
            const link = document.createElement('a');
            link.href = result.data.exportBusinessList.fileUrl;
            link.download = 'BusinessesList';
            link.click();
        });
    };

    return (
        <>
            <MainCard title="Business List" content={false} sx={{ position: 'relative' }}>
                <CardContent>
                    <Stack direction={{ sm: 'row' }} justifyContent="space-between" alignItems="center" gap={1}>
                        <Box width={{ xs: '100%', md: '150px', lg: '200px' }}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={debouncedSearch}
                                placeholder="Search business"
                                size="small"
                            />
                        </Box>

                        <Stack direction={{ sm: 'row' }} alignItems={'center'} gap={1}>
                            <Box width={{ xs: '100%', md: '150px', lg: '200px' }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<InsertInvitationIcon />}
                                    size="medium"
                                    fullWidth
                                    onClick={() => {
                                        navigate('/businesses/invite');
                                    }}
                                >
                                    Invite new business
                                </Button>
                            </Box>

                            <Box width={{ xs: '100%', md: '150px', lg: '200px' }}>
                                <StaticSelect
                                    name="displayTimeIncrements"
                                    value={statusFilter}
                                    onChange={(e) => {
                                        setStatusFilter(e?.target?.value);
                                    }}
                                    placeholder={'Select Status'}
                                    options={businessStatusFilterData}
                                    onBlur={() => {}}
                                />
                            </Box>

                            <Box width={{ xs: '100%', md: '150px', lg: '200px' }}>
                                <LocalizationProvider name="startDate" dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        InputAdornmentProps={{ position: 'start' }}
                                        inputFormat="DD MMMM YYYY"
                                        components={{
                                            OpenPickerIcon: datePickerIcon
                                        }}
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(dayjs(e)?.endOf('day'));
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: 'Start Date',
                                                    sx: {
                                                        py: '10px'
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box width={{ xs: '100%', md: '150px', lg: '200px' }}>
                                <LocalizationProvider name="endDate" dateAdapter={AdapterDayjs}>
                                    <Stack spacing={3}>
                                        <DatePicker
                                            InputAdornmentProps={{ position: 'start' }}
                                            inputFormat="DD MMMM YYYY"
                                            components={{
                                                OpenPickerIcon: datePickerIcon
                                            }}
                                            value={endDate}
                                            onChange={(e) => {
                                                setEndDate(dayjs(e)?.endOf('day'));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: 'End Date',
                                                        sx: {
                                                            py: '10px'
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Box>
                            <Box width={{ xs: '100%', md: '150px', lg: '200px' }}>
                                <Button
                                    variant="outlined"
                                    disabled={exporting}
                                    startIcon={<FileDownloadIcon />}
                                    size="medium"
                                    fullWidth
                                    onClick={() => {
                                        handleExportBusiness();
                                    }}
                                >
                                    {exporting ? 'Exporting...' : ' Export Businesses'}
                                </Button>
                            </Box>
                        </Stack>
                    </Stack>
                </CardContent>

                {/* table */}
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <BusinessTemplate {...{ businessList /*handleEdit */, handleRefetch: refetch, loading }} />

                        {/* table pagination */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 30]}
                            component={Paper}
                            count={data?.listBusiness?.pagination?.total || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </MainCard>
        </>
    );
};

export default BusinessList;
