// material-ui
import { useEffect, useState } from 'react';

import { Box, FormControl, Paper, TablePagination } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import useGQL from 'hooks/useGQL';
import { ArrangementOrder1 } from 'types';
import MainCard from 'ui-component/cards/MainCard';
import PaidMembershipList from './list';

const PaidMembership = () => {
    const [paidMembershipList, setPaidMembershipList] = useState([]);
    const [order, setOrder] = useState<ArrangementOrder1>('asc');
    const [orderBy, setOrderBy] = useState<string>('_id');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [count, setCount] = useState<number>(1);
    const [fromDate, setFromDate] = useState<any>('');
    const [toDate, setToDate] = useState<any>('');
    const [dateRange, setDateRange] = useState<any>([]);
    const [startDate, endDate] = dateRange;

    const { LIST_ALL_PAID_MEMBERSHIPS } = useGQL();

    const { error, loading, data, refetch } = LIST_ALL_PAID_MEMBERSHIPS({
        skip: page > 0 ? rowsPerPage * page : 0,
        limit: rowsPerPage,
        fromDate: fromDate || null,
        toDate: toDate || null
    });

    useEffect(() => {
        if (data?.listPaidMembership?.data) {
            setPaidMembershipList(data?.listPaidMembership?.data);
        }

        if (error) {
            console.log(error);
        }
    }, [data]);

    if (error) {
        console.log(error);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target?.value) {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        }
    };

    const handleChange = (val) => {
        setFromDate(val ? val[0] : '');
        setToDate(val ? val[1] : '');
    };

    const handleDateRangeChange = (val) => {
        setDateRange(val);
        if (handleChange) {
            handleChange(val);
        }
    };

    return (
        <>
            <MainCard title="List of members with paid subscription" content={false} sx={{ position: 'relative' }}>
                <Box width={{ xs: '100%', md: '150px', lg: '200px' }} sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
                    <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleDateRangeChange}
                                isClearable
                                placeholderText="Date"
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Box>
                <PaidMembershipList {...{ paidMembershipList, handleRefetch: refetch, loading }} />
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 30]}
                    component={Paper}
                    count={data?.listPaidMembership?.pagination?.total || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default PaidMembership;
