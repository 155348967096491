import { HeadCell1 } from 'types';

// table header options
export const headCells: HeadCell1[] = [
    {
        id: 'businessName',
        numeric: false,
        label: 'Business name',
        align: 'left',
        sort: false
    },
    {
        id: 'totalSms',
        numeric: true,
        label: 'Number of SMSs',
        align: 'left',
        sort: false
    },
    {
        id: 'cost',
        numeric: true,
        label: 'Total amount accrued',
        align: 'left',
        sort: false
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'left',
        sort: false
    }
];
