import { HeadCell1 } from 'types';
import * as Yup from 'yup';

// table header options
export const headCells: HeadCell1[] = [
    {
        id: 'businessName',
        numeric: true,
        label: 'Business Name',
        align: 'center',
        sort: false
    },
    {
        id: 'abn',
        numeric: false,
        label: 'ABN',
        align: 'center',
        sort: true
    },
    {
        id: 'email',
        numeric: false,
        label: 'Email',
        align: 'center',
        sort: true
    },
    {
        id: 'phone',
        numeric: false,
        label: 'Phone',
        align: 'center',
        sort: true
    },
    {
        id: 'dateJoined',
        numeric: false,
        label: 'Date Joined',
        align: 'center',
        sort: true
    },

    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'center',
        sort: true
    },
    {
        id: 'action',
        numeric: false,
        label: 'Action',
        align: 'center',
        sort: true
    }
];

export const optionYesNo = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
];

export const optionPromoDuration = [
    {
        label: 'once',
        value: 'once'
    },
    {
        label: '1 month',
        value: '1'
    },
    {
        label: '2 months',
        value: '2'
    },
    {
        label: '3 months',
        value: '3'
    },
    {
        label: '6 months',
        value: '6'
    },
    {
        label: '12 months',
        value: '12'
    },
    {
        label: 'indefinite',
        value: 'indefinite'
    }
];

Yup.setLocale({
    string: {
        matches: '${label} must contain only numbers',
        min: '${label} must be at least ${min} characters',
        max: '${label} must be at most ${max} characters'
    }
});

export const defaultValue = {
    _id: '',
    businessName: '',
    abn: null,
    logo: '',
    primaryLocation: {
        name: '',
        email: '',
        phone: '',
        locationAddress: {
            type: 'Point',
            city: '',
            coordinates: [],
            country: '',
            displayLocation: '',
            postalCode: '',
            state: '',
            street: ''
        }
    },
    otherLocations: [
        {
            name: null,
            email: '',
            phone: '',
            locationAddress: {
                type: 'Point',
                city: '',
                coordinates: [],
                country: '',
                displayLocation: '',
                postalCode: '',
                state: '',
                street: ''
            }
        }
    ]
};

export const defaultValueProfile = {
    _id: '',
    firstName: '',
    lastName: '',
    dateOfBirth: Date,
    email: '',
    phone: '',
    profilePicture: ''
};
