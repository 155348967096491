import { gql } from '@apollo/client';

export const LIST_OUTSTANDING_INVOICES_QUERY = gql`
    query GetSmsCostByBusinessId($input: ListSmsCostHistoryItemsDTO!) {
        getSmsCostByBusinessId(input: $input) {
            smsList {
                totalSms
                status
                cost
                businessName
                businessId
            }

            pagination {
                hasNextPage
                total
            }
        }
    }
`;

export const REVENUE_BY_SUBSCRIPTION_QUERY = gql`
    query GetNewMembershipByClass($input: AppointmentReportInput!) {
        getNewMembershipByClass(input: $input) {
            data {
                month

                count
                totalAmount
            }
        }
    }
`;

export const PAYMENT_LIST_QUERY = gql`
    query GetPaymentList($input: ListSmsCostHistoryItemsDTO!) {
        getPaymentList(input: $input) {
            data {
                paymentType
                paidDate
                businessName
                amount
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const MEMBERSHIP_TYPE_LIST_QUERY = gql`
    query GetSubscriptionPlans {
        getSubscriptionPlans {
            subscriptions {
                _id
                description
                duration
                maximumValue
                minimumValue
                name
                price
                slug
                stripePlanId
                stripePriceId
            }
        }
    }
`;

export const ALL_BUSINESS_LIST_QUERY = gql`
    query ListAllBusiness {
        listAllBusiness {
            data {
                _id
                abn
                businessName
                dateJoined
                email
                phone
                status
            }
        }
    }
`;
