import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';

import Spinner from 'components/spinner';
import { headCells } from 'constants/paymentList';

type SurveyListProps = {
    paymentList: any;
    handleRefetch: () => void;
    loading: any;
};

const PaymentList = ({ paymentList, handleRefetch, loading }: SurveyListProps) => {
    const {
        sort: { order, orderBy }
    } = useSelector((state: any) => state.table);

    useEffect(() => {
        handleRefetch();
    }, [order, orderBy]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                return (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {paymentList.length == 0 ? (
                            <TableRow sx={{ borderBottom: 'none' }}>
                                <TableCell align="left"> </TableCell>
                                <TableCell align="left">
                                    {' '}
                                    <h1>No Items</h1>
                                </TableCell>
                                <TableCell align="left"> </TableCell>
                                <TableCell align="left"> </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {paymentList.map((paidMembership) => (
                                    <TableRow key={paidMembership._id}>
                                        <TableCell align="left">{paidMembership.businessName}</TableCell>
                                        <TableCell align="left">{paidMembership.paymentType}</TableCell>
                                        <TableCell align="left">${paidMembership.amount}</TableCell>

                                        <TableCell align="left">
                                            {paidMembership.paidDate ? dayjs(paidMembership.paidDate).format('D MMMM YYYY') : '-'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default PaymentList;
