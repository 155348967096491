import { Paper, Typography, useTheme } from '@mui/material';

import TabSection from 'components/tabs';
import ERoutes from 'routes/ERoutes';
import MembershipReport from './membership';
import NpsReport from './nps';
import SmsReport from './outstandingInvoice';
import RevenueReport from './revenue';

const Reports = () => {
    const theme = useTheme();
    const tabs = [
        {
            name: 'Revenue',
            component: () => <RevenueReport />
        },
        {
            name: 'Outstanding Invoices',
            component: () => <SmsReport />
        },
        {
            name: 'Membership',
            component: () => <MembershipReport />
        },
        {
            name: 'NPS',
            component: () => <NpsReport />
        }
    ];
    return (
        <>
            <Paper className="p-10-15">
                <Typography variant="h3" color={theme.palette.grey[900]}>
                    Reports
                </Typography>
            </Paper>
            <TabSection tabs={tabs} isLoading={false} mainRoute={ERoutes.reports} />
        </>
    );
};

export default Reports;
